import React, { Component } from "react";
import moment from "moment";
import "antd/dist/antd.css";
import { Collapse, DatePicker } from "antd";
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Popconfirm, notification } from "antd";
import { Image } from "antd";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";

const { Panel } = Collapse;
const axios = require("axios");

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

class JobEdit extends Component {
  state = {
    user: null,
    projects_list: [],
    job: {
      name1: "",
      name2: "",
      name3: "",
      scheduled_on: null,
      street_address: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
      description: "",
      contact_name: "",
      phone_number: "",
      email_address: "",
      wholeseller1: 0,
      wholeseller2: 0,
      wholeseller3: 0,
      project_type: 0,
      contractor_id: 0,
      status: "pending",
      assigned_wholeseller: 0,
      projects: [],
    },
    scheduled_on: null,
    contractors_list: [],
    wholesellers_list: [],
    pjkt_edit_index: -1,
    je_edit_index: -1,
    ws_add: {
      first_name: "",
      last_name: "",
      street_address: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
      phone_number: "",
      email_address: "",
    },
  };
  job_id = null;
  componentDidMount() {
    if (localStorage.getItem("user") != null)
      this.setState(
        { user: JSON.parse(localStorage.getItem("user")) },
        function () {
          this.initComp();
        }
      );
  }
  handleAddingWS = (e) => {
    this.props.blockUI(true);
    axios
      .post(process.env.REACT_APP_API_URL + "ws/add", this.state.ws_add)
      .then((resp) => {
        if (resp.data.status)
          notification.success({
            message: "Wholesale Distributor Added.",
            description:
              "Wholesale Distributor has been added. It's available for selection in wholesale ditributors dropdown.",
            placement: "bottomRight",
          });
      })
      .catch((resp) => {
        let msg = [];
        if (resp.response) {
          resp = resp.response;
          if (resp.status == 422) {
            for (var i in resp.data) {
              msg.push(resp.data[i]);
            }
          }
        }
        notification.error({
          message: "Failed",
          description: msg,
          placement: "bottomRight",
          duration: 60,
        });
      })
      .then((resp) => {
        this.props.blockUI(false);
        this.fetchWholesellersList();
      });
  };
  handleEditJob = (e) => {
    this.props.blockUI(true);
    let obj = this.state.job;
    obj["job_equipment"] = this.state.job_equipment;
    axios
      .post(
        process.env.REACT_APP_API_URL +
          "jobs/update_b/" +
          this.props.match.params.job_id,
        obj
      )
      .then((resp) => {
        if (resp.data.status)
          notification.success({
            message: "Job edit.",
            description: "Job has been edited.",
            placement: "bottomRight",
          });
        this.props.history.push("/");
      })
      .catch((resp) => {
        let msg = [];
        if (resp.response) {
          resp = resp.response;
          if (resp.status == 422) {
            for (var i in resp.data) {
              msg.push(resp.data[i]);
            }
          }
        }

        notification.error({
          message: "Failed to edit Job.",
          description: msg,
          placement: "bottomRight",
          duration: 60,
        });
      })
      .then((resp) => {
        this.props.blockUI(false);
      });
  };
  beforeUpload = (file, i, ji, img_num) => {
    //console.log(file.originFileObj);
    //return false;
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    getBase64(file, (imageUrl) => {
      console.log(imageUrl);
      let job = this.state.job;
      job.projects[i].job_equipment[ji][img_num] = imageUrl;
      switch (img_num) {
        case "img1_path":
          job.projects[i].job_equipment[ji].loading1 = false;
          break;
        case "img2_path":
          job.projects[i].job_equipment[ji].loading2 = false;
          break;
        case "img3_path":
          job.projects[i].job_equipment[ji].loading3 = false;
          break;
      }

      this.setState(job);
    });
    return false;
  };
  addJobEquipment = (e, i) => {
    let job = this.state.job;

    job.projects[i].job_equipment.push({
      project_equipment_id: 0,
      name: "",
      size: "",
      price: 0,
      qty: 0,
      description: "",
      mode_edit: true,
      img1_path: "",
      img2_path: "",
      img3_path: "",
      loading1: false,
      loading2: false,
      loading3: false,
    });
    this.setState(job);
  };
  initComp() {
    this.props.blockUI(true);
    const job_id = (this.job_id = this.props.match.params.job_id);
    //
    axios
      .get(process.env.REACT_APP_API_URL + "/jobs/view/" + job_id)
      .then((resp) => {
        let job = this.state.job;
        job.name1 = resp.data.item.name1;
        job.name2 = resp.data.item.name2;
        job.name3 = resp.data.item.name3;
        job.scheduled_on = moment(resp.data.item.scheduled_on);
        job.street_address = resp.data.item.street_address;
        job.city = resp.data.item.city;
        job.state = resp.data.item.state;
        job.postal_code = resp.data.item.postal_code;
        job.country = resp.data.item.country;
        job.description = resp.data.item.description;
        job.contact_name = resp.data.item.contact_name;
        job.phone_number = resp.data.item.phone_number;
        job.email_address = resp.data.item.email_address;
        job.project_type = resp.data.item.project_id;
        job.wholeseller1 = resp.data.item.wholeseller1;
        job.wholeseller2 = resp.data.item.wholeseller2;
        job.wholeseller3 = resp.data.item.wholeseller3;
        job.contractor_id = resp.data.item.contractor_id;
        job.status = resp.data.item.status;
        job.assigned_wholeseller = resp.data.item.assigned_wholeseller;
        job.projects = resp.data.projects;

        this.setState({ job: job });
      })
      .catch((resp) => {})
      .then((resp) => {
        this.props.blockUI(false);
      });
    //fetch and fill projects list dropdown
    axios
      .get(process.env.REACT_APP_API_URL + "projects/list")
      .then((resp) => {
        this.setState({ projects_list: resp.data.items });
      })
      .catch((err) => {
        console.error(err);
      })
      .then((any) => {});
    //
    this.fetchContractorsList();
    this.fetchWholesellersList();
  }
  fetchContractorsList = () => {
    //fetch and fill Wholesellers list dropdown
    axios
      .get(process.env.REACT_APP_API_URL + "users/listcontractors")
      .then((resp) => {
        this.setState({ contractors_list: resp.data.items });
      })
      .catch((err) => {
        console.error(err);
      })
      .then((any) => {});
    //
  };
  fetchWholesellersList = () => {
    //fetch and fill Wholesellers list dropdown
    let url = process.env.REACT_APP_API_URL + "ws/list";
    if (this.state.user.role == "contractor") {
      url += "/" + this.state.user.id;
    }
    axios
      .get(url)
      .then((resp) => {
        this.setState({ wholesellers_list: resp.data.items });
      })
      .catch((err) => {
        console.error(err);
      })
      .then((any) => {});
    //
  };
  handleProjectTypeChange = (e, index) => {
    this.props.blockUI(true);
    let job = this.state.job;
    job.project_type = e.target.value;

    axios
      .get(
        process.env.REACT_APP_API_URL +
          "projects/view/" +
          this.state.job.project_type
      )
      .then((data) => {
        job.projects[index].job_equipment = [];
        job.projects[index].project_id = data.data.item.id;
        let jes = data.data.equipments;
        let temp_jes = [];
        for (var i = 0; i < jes.length; i++) {
          let j_e = {
            project_equipment_id: jes[i].id,
            name: jes[i].name,
            size: jes[i].size,
            price: 0,
            qty: 0,
            description: jes[i].description,
            mode_edit: true,
            img1_path: "",
            img2_path: "",
            img3_path: "",
            loading1: false,
            loading2: false,
            loading3: false,
          };
          job.projects[index].job_equipment.push(j_e);
        }

        this.setState(job);
      })
      .catch((err) => {
        console.log(err);
      })
      .then((resp) => {
        this.props.blockUI(false);
      });
  };
  editJobEquipment = (e, i, ji) => {
    let job = this.state.job;
    job.projects[i].job_equipment[ji].mode_edit = !job.projects[i]
      .job_equipment[ji].mode_edit;
    this.setState(job);
  };
  removeJobProject = (e, i) => {
    let job = this.state.job;
    job.projects.splice(i, 1);
    this.setState(job);
  };
  removeJobEquipment = (e, i, ji) => {
    let job = this.state.job;
    job.projects[i].job_equipment.splice(ji, 1);
    this.setState(job);
  };
  handleJobEquipmentChange = (e, i, ji, key) => {
    let job = this.state.job;
    job.projects[i].job_equipment[ji][key] = e.target.value;
    this.setState(job);
  };
  addAnotherProject = (e) => {
    let job = this.state.job;
    job.projects.push({ id: 0, job_equipment: [] });
    this.setState({ job });
  };
  render() {
    if (this.state.user != null)
      return (
        <React.Fragment>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4>
                    {this.state.user.role == "contractor" ? (
                      <React.Fragment>
                        <div className="row">
                          <div className="col-md-4">
                            {"Edit Job" +
                              this.state.job.name1 +
                              " (" +
                              this.job_id +
                              ")"}
                          </div>
                          {/*<div className="col-md-8">
                            {" "}
                            <div className="row">
                              {" "}
                              <label
                                className="col-md-2"
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "normal",
                                }}
                              >
                                Job Status
                              </label>
                              <div className="col-md-10">
                                <select
                                  className="form-control"
                                  value={this.state.job.status}
                                  onChange={(e) => {
                                    let temp = this.state.job;
                                    temp.status = e.target.value;
                                    this.setState({ job: temp });
                                  }}
                                >
                                  <option value="pending">Pending</option>
                                  <option value="forwarded">Forwarded</option>
                                  <option value="completed">Completed</option>
                                </select>
                              </div>
                            </div>
                          </div>*/}
                        </div>
                      </React.Fragment>
                    ) : (
                      "Edit Job" +
                      this.state.job.name1 +
                      " (" +
                      this.job_id +
                      ")"
                    )}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          {this.state.user.role != "contractor" ? (
            <div className="row">
              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label>Contractor</label>
                          <select
                            className="form-control"
                            value={this.state.job.contractor_id}
                            onChange={(e) => {
                              let temp = this.state.job;
                              temp.contractor_id = e.target.value;
                              this.setState({ job: temp });
                            }}
                          >
                            <option></option>
                            {this.state.contractors_list.map((con) => (
                              <option value={con.id}>
                                {con.first_name +
                                  " " +
                                  con.last_name +
                                  " (" +
                                  con.id +
                                  ")"}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label>Status</label>
                          <select
                            className="form-control"
                            value={this.state.job.status}
                            onChange={(e) => {
                              let temp = this.state.job;
                              temp.status = e.target.value;
                              this.setState({ job: temp });
                            }}
                          >
                            <option value="pending">Submitted</option>
                            <option value="forwarded">In Review</option>
                            <option value="completed">Complete</option>
                          </select>
                        </div>
                      </div>
                      {/***hiding assigned wholesaler 'cause that idiot doesn't know what shes doing 
                      <div className="col-md-4">
                        <div className="form-group row">
                          <label>Assigned Wholesale Distributor</label>
                          <select
                            className="form-control"
                            value={this.state.job.assigned_wholeseller}
                            onChange={(e) => {
                              let temp = this.state.job;
                              temp.assigned_wholeseller = e.target.value;
                              this.setState({ job: temp });
                            }}
                          >
                            <option></option>
                            {this.state.wholesellers_list.map((ws) => (
                              <option value={ws.id}>
                                {ws.name + " (" + ws.id + ")"}
                              </option>
                            ))}
                          </select>
                        </div>
                            </div>
                            */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="row">
            <div className="col-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <form
                    className="form-sample"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label>Project Name</label>
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.job.name1}
                            onChange={(e) => {
                              let temp = this.state.job;
                              temp.name1 = e.target.value;
                              this.setState({ job: temp });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label>Date Time</label>
                          <DatePicker
                            showTime
                            inputReadOnly
                            className="form-control"
                            style={{
                              borderColor: "gray !important",
                              paddingTop: "5px",
                            }}
                            format="MM-DD-YYYY h:mm a"
                            value={this.state.job.scheduled_on}
                            onChange={(value, dateString) => {
                              let temp = this.state.job;
                              temp.scheduled_on = value.format(
                                "YYYY-MM-DD HH:mm:ss"
                              );
                              this.setState({ job: temp });
                            }}
                            onOk={(value) => {
                              let temp = this.state.job;
                              temp.scheduled_on = value.format(
                                "YYYY-MM-DD HH:MM:ss"
                              );
                              this.setState({ job: temp });
                            }}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label>Address</label>
                          <textarea
                            type="text"
                            className="form-control"
                            value={this.state.job.street_address}
                            onChange={(e) => {
                              let temp = this.state.job;
                              temp.street_address = e.target.value;
                              this.setState({ job: temp });
                            }}
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label>Notes</label>
                          <textarea
                            className="form-control"
                            value={this.state.job.description}
                            onChange={(e) => {
                              let temp = this.state.job;
                              temp.description = e.target.value;
                              this.setState({ job: temp });
                            }}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/*Adding job equipmetns*/}
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h5>Projects</h5>
                  {/**************project type */}
                  {this.state.job.projects.map((project, i) => (
                    <React.Fragment>
                      <hr />
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <div className="row">
                              <label className="col-md-6">
                                SELECT PRODUCT TYPE &nbsp;
                                <Popconfirm
                                  title="Are you sure?"
                                  okText="Yes"
                                  cancelText="No"
                                  onConfirm={(e) => this.removeJobProject(e, i)}
                                >
                                  <button className="btn btn-sm btn-outline-danger">
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </Popconfirm>
                              </label>
                              <div className="col-md-6">
                                <select
                                  className="form-control"
                                  value={
                                    project.project_id !== undefined
                                      ? project.project_id
                                      : ""
                                  }
                                  onChange={(e) =>
                                    this.handleProjectTypeChange(e, i)
                                  }
                                >
                                  <option value="0">Select Product Type</option>
                                  {this.state.projects_list.map((pj) => (
                                    <option key={pj.id} value={pj.id}>
                                      {pj.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{ overflow: "auto" }}>
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Description</th>
                              <th>Size</th>
                              <th>Quantity</th>
                              {/*<th>Price</th>*/}
                              <th>Image 1</th>
                              <th>Image 2</th>
                              <th>Image 3</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.job.projects[i].job_equipment !==
                            undefined
                              ? this.state.job.projects[i].job_equipment.map(
                                  (je, ji) => (
                                    <tr key={ji}>
                                      <td>
                                        {je.mode_edit == true ? (
                                          <input
                                            type="text"
                                            className="form-control"
                                            value={je.name}
                                            onChange={(e) =>
                                              this.handleJobEquipmentChange(
                                                e,
                                                i,
                                                ji,
                                                "name"
                                              )
                                            }
                                          />
                                        ) : (
                                          <p>{je.name}</p>
                                        )}
                                      </td>
                                      <td>
                                        {je.mode_edit == true ? (
                                          <input
                                            className="form-control"
                                            type="text"
                                            value={je.description}
                                            onChange={(e) =>
                                              this.handleJobEquipmentChange(
                                                e,
                                                i,
                                                ji,
                                                "description"
                                              )
                                            }
                                          />
                                        ) : (
                                          <p>{je.description}</p>
                                        )}
                                      </td>
                                      <td>
                                        {je.mode_edit == true ? (
                                          <input
                                            className="form-control"
                                            type="text"
                                            value={je.size}
                                            onChange={(e) =>
                                              this.handleJobEquipmentChange(
                                                e,
                                                i,
                                                ji,
                                                "size"
                                              )
                                            }
                                          />
                                        ) : (
                                          <p>{je.size}</p>
                                        )}
                                      </td>
                                      <td>
                                        {je.mode_edit == true ? (
                                          <input
                                            className="form-control"
                                            type="text"
                                            value={je.qty}
                                            onChange={(e) =>
                                              this.handleJobEquipmentChange(
                                                e,
                                                i,
                                                ji,
                                                "qty"
                                              )
                                            }
                                          />
                                        ) : (
                                          <p>{je.qty}</p>
                                        )}
                                      </td>
                                      {/*<td>
                                        {je.mode_edit == true ? (
                                          <input
                                            className="form-control"
                                            type="text"
                                            value={je.price}
                                            onChange={(e) =>
                                              this.handleJobEquipmentChange(
                                                e,
                                                i,
                                                ji,
                                                "price"
                                              )
                                            }
                                          />
                                        ) : (
                                          <p>{je.price}</p>
                                        )}
                                        </td>*/}
                                      <td>
                                        {je.mode_edit == true ? (
                                          <Upload
                                            name="avatar"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            beforeUpload={(file) =>
                                              this.beforeUpload(
                                                file,
                                                i,
                                                ji,
                                                "img1_path"
                                              )
                                            }
                                          >
                                            {je.img1_path ? (
                                              <img
                                                src={
                                                  je.img1_path.indexOf(
                                                    "data:image/png;base64,"
                                                  ) == -1
                                                    ? "data:image/png;base64," +
                                                      je.img1_path
                                                    : je.img1_path
                                                }
                                                alt="avatar"
                                                style={{ width: "100%" }}
                                              />
                                            ) : (
                                              <div>
                                                {je.loading1 ? (
                                                  <LoadingOutlined />
                                                ) : (
                                                  <PlusOutlined />
                                                )}
                                                <div style={{ marginTop: 8 }}>
                                                  Upload
                                                </div>
                                              </div>
                                            )}
                                          </Upload>
                                        ) : (
                                          <Image
                                            src={
                                              je.img1_path.indexOf(
                                                "data:image/png;base64,"
                                              ) == -1
                                                ? "data:image/png;base64," +
                                                  je.img1_path
                                                : je.img1_path
                                            }
                                          />
                                        )}
                                      </td>
                                      <td>
                                        {je.mode_edit == true ? (
                                          <Upload
                                            name="avatar"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            beforeUpload={(file) =>
                                              this.beforeUpload(
                                                file,
                                                i,
                                                ji,
                                                "img2_path"
                                              )
                                            }
                                          >
                                            {je.img2_path ? (
                                              <img
                                                src={
                                                  je.img2_path.indexOf(
                                                    "data:image/png;base64,"
                                                  ) == -1
                                                    ? "data:image/png;base64," +
                                                      je.img2_path
                                                    : je.img2_path
                                                }
                                                alt="avatar"
                                                style={{ width: "100%" }}
                                              />
                                            ) : (
                                              <div>
                                                {je.loading2 ? (
                                                  <LoadingOutlined />
                                                ) : (
                                                  <PlusOutlined />
                                                )}
                                                <div style={{ marginTop: 8 }}>
                                                  Upload
                                                </div>
                                              </div>
                                            )}
                                          </Upload>
                                        ) : (
                                          <Image
                                            src={
                                              je.img2_path.indexOf(
                                                "data:image/png;base64,"
                                              ) == -1
                                                ? "data:image/png;base64," +
                                                  je.img2_path
                                                : je.img2_path
                                            }
                                          />
                                        )}
                                      </td>
                                      <td>
                                        {je.mode_edit == true ? (
                                          <Upload
                                            name="avatar"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            beforeUpload={(file) =>
                                              this.beforeUpload(
                                                file,
                                                i,
                                                ji,
                                                "img3_path"
                                              )
                                            }
                                          >
                                            {je.img3_path ? (
                                              <img
                                                src={
                                                  je.img3_path.indexOf(
                                                    "data:image/png;base64,"
                                                  ) == -1
                                                    ? "data:image/png;base64," +
                                                      je.img3_path
                                                    : je.img3_path
                                                }
                                                alt="avatar"
                                                style={{ width: "100%" }}
                                              />
                                            ) : (
                                              <div>
                                                {je.loading3 ? (
                                                  <LoadingOutlined />
                                                ) : (
                                                  <PlusOutlined />
                                                )}
                                                <div style={{ marginTop: 8 }}>
                                                  Upload
                                                </div>
                                              </div>
                                            )}
                                          </Upload>
                                        ) : (
                                          <Image
                                            src={
                                              je.img3_path.indexOf(
                                                "data:image/png;base64,"
                                              ) == -1
                                                ? "data:image/png;base64," +
                                                  je.img3_path
                                                : je.img3_path
                                            }
                                          />
                                        )}
                                      </td>
                                      <td>
                                        <button
                                          className="btn btn-sm btn-outline-warning"
                                          onClick={(e) =>
                                            this.editJobEquipment(e, i, ji)
                                          }
                                        >
                                          <i
                                            className={
                                              je.mode_edit == true
                                                ? "fa fa-check"
                                                : "fa fa-pencil"
                                            }
                                          ></i>
                                        </button>
                                        &nbsp;
                                        <Popconfirm
                                          title="Are you sure?"
                                          okText="Yes"
                                          cancelText="No"
                                          onConfirm={(e) =>
                                            this.removeJobEquipment(e, i, ji)
                                          }
                                          className="btn btn-sm btn-outline-danger"
                                        >
                                          <button className="btn btn-sm btn-outline-danger">
                                            <i className="fa fa-trash"></i>
                                          </button>
                                        </Popconfirm>
                                      </td>
                                    </tr>
                                  )
                                )
                              : ""}
                          </tbody>
                        </table>
                      </div>
                      <div style={{ display: "inline-block", width: "100%" }}>
                        <div className="col-md-12">
                          <button
                            type="button"
                            className="btn btn-outline-info"
                            style={{
                              marginLeft: "-12px",
                              marginTop: "7px",
                              backgroundColor: "#102EB4",
                              color: "white",
                            }}
                            onClick={(e) => this.addJobEquipment(e, i)}
                          >
                            Add Job Equipment
                          </button>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}

                  {/*********************************************************ABOVE IS A WHOLE PROJECT */}
                  <div>
                    <button
                      type="button"
                      className="btn btn-outline-info"
                      style={{
                        marginRight: "-12px",
                        marginTop: "15px",
                        marginBottom: "-15px",
                        backgroundColor: "#102EB4",
                        color: "white",
                      }}
                      onClick={this.addAnotherProject}
                    >
                      {this.state.job.projects.length == 0
                        ? "Add Product Type"
                        : "Add Another Product Type"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/***** */}
          {/***WholeSale distributors section */}
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h5>
                    Select a wholesale distributor to send BOM to (Optional).
                    <small>
                      {" "}
                      To add or edit your wholesale distributor please visit My
                      Wholesalers section
                    </small>
                  </h5>
                  <hr />
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group row">
                        <label>Wholesale Distributor 1</label>
                        <select
                          className="form-control"
                          value={this.state.job.wholeseller1}
                          onChange={(e) => {
                            let job = this.state.job;
                            job.wholeseller1 = e.target.value;
                            this.setState({ job });
                          }}
                        >
                          <option value="0"></option>
                          {this.state.wholesellers_list.map((ws) => (
                            <option
                              key={ws.id}
                              value={ws.id}
                              selected={ws.id == this.state.job.wholeseller1}
                            >
                              {ws.name + " (" + ws.id + ")"}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group row">
                        <label>Wholesale Distributor 2</label>
                        <select
                          className="form-control"
                          value={this.state.job.wholeseller2}
                          onChange={(e) => {
                            let job = this.state.job;
                            job.wholeseller2 = e.target.value;
                            this.setState({ job });
                          }}
                        >
                          <option value="0"></option>
                          {this.state.wholesellers_list.map((ws) => (
                            <option
                              key={ws.id}
                              value={ws.id}
                              selected={ws.id == this.state.job.wholeseller2}
                            >
                              {ws.name + " (" + ws.id + ")"}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group row">
                        <label>Wholesale Distributor 3</label>
                        <select
                          className="form-control"
                          value={this.state.job.wholeseller3}
                          onChange={(e) => {
                            let job = this.state.job;
                            job.wholeseller3 = e.target.value;
                            this.setState({ job });
                          }}
                        >
                          <option value="0"></option>
                          {this.state.wholesellers_list.map((ws) => (
                            <option
                              key={ws.id}
                              value={ws.id}
                              selected={ws.id == this.state.job.wholeseller3}
                            >
                              {ws.name + " (" + ws.id + ")"}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {this.state.wholesellers_list.length < 0 ? (
                    <Collapse
                      style={{
                        marginLeft: "-13px",
                        marginTop: "0px",
                        marginRight: "-13px",
                      }}
                    >
                      <Panel header="Add A New Wholesale Distributor">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group row">
                              <label>First Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.ws_add.first_name}
                                onChange={(e) => {
                                  let temp = this.state.ws_add;
                                  temp.first_name = e.target.value;
                                  this.setState({ ws_add: temp });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group row">
                              <label> Last Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.ws_add.last_name}
                                onChange={(e) => {
                                  let temp = this.state.ws_add;
                                  temp.last_name = e.target.value;
                                  this.setState({ ws_add: temp });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group row">
                              <label>Phone Number</label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.ws_add.phone_number}
                                onChange={(e) => {
                                  let temp = this.state.ws_add;
                                  temp.phone_number = e.target.value;
                                  this.setState({ ws_add: temp });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group row">
                              <label> Address</label>
                              <textarea
                                type="text"
                                className="form-control"
                                value={this.state.ws_add.street_address}
                                onChange={(e) => {
                                  let temp = this.state.ws_add;
                                  temp.street_address = e.target.value;
                                  this.setState({ ws_add: temp });
                                }}
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group row">
                              <label> City</label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.ws_add.city}
                                onChange={(e) => {
                                  let temp = this.state.ws_add;
                                  temp.city = e.target.value;
                                  this.setState({ ws_add: temp });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group row">
                              <label> State</label>
                              {/*<input
                              type="text"
                              className="form-control"
                              value={this.state.ws_add.state}
                              onChange={(e) => {
                                let temp = this.state.ws_add;
                                temp.state = e.target.value;
                                this.setState({ ws_add: temp });
                              }}
                            />*/}
                              <RegionDropdown
                                country={this.state.ws_add.country}
                                value={this.state.ws_add.state}
                                onChange={(val) => {
                                  let temp = this.state.ws_add;
                                  temp.state = val;
                                  this.setState({ ws_add: temp });
                                }}
                                classes="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group row">
                              <label> Zip Code</label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.ws_add.postal_code}
                                onChange={(e) => {
                                  let temp = this.state.ws_add;
                                  temp.postal_code = e.target.value;
                                  this.setState({ ws_add: temp });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group row">
                              <label>Country</label>
                              {/*<input
                              type="text"
                              className="form-control"
                              value={this.state.ws_add.country}
                              onChange={(e) => {
                                let temp = this.state.ws_add;
                                temp.country = e.target.value;
                                this.setState({ ws_add: temp });
                              }}
                            />*/}
                              <CountryDropdown
                                value={this.state.ws_add.country}
                                onChange={(val) => {
                                  let temp = this.state.ws_add;
                                  temp.country = val;
                                  this.setState({ ws_add: temp });
                                }}
                                priorityOptions={["US"]}
                                classes="form-control"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group row">
                              <label>Email</label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.ws_add.email_address}
                                onChange={(e) => {
                                  let temp = this.state.ws_add;
                                  temp.email_address = e.target.value;
                                  this.setState({ ws_add: temp });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 pull-right">
                            <button
                              className="btn btn-outline-info pull-right"
                              onClick={this.handleAddingWS}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </Panel>
                    </Collapse>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          {/******************************************* */}
          <div className="row">
            <div className="col-md-12">
              <button
                className="btn btn-lg btn-success"
                type="button"
                onClick={this.handleEditJob}
                style={{ backgroundColor: "#102EB4", border: "0" }}
              >
                Submit
              </button>
            </div>
          </div>
        </React.Fragment>
      );
    return <React.Fragment></React.Fragment>;
  }
}

export default JobEdit;
