import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";
import "antd/dist/antd.css";
import { Spin } from "antd";
import MasterLayout from "./layouts/MasterLayout";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import JobsList from "./Pages/JobsList";
import JobAdd from "./Pages/JobAdd";
import JobEdit from "./Pages/JobEdit";
import JobView from "./Pages/JobView";
import UsersList from "./Pages/UsersList";
import ContractorsList from "./Pages/ContractorsList";
import UserAdd from "./Pages/UserAdd";
import UserEdit from "./Pages/UserEdit";
import MyWSList from "./Pages/MyWSList";
import WSList from "./Pages/WSList";
import WSEdit from "./Pages/WSEdit";
import Dashboard from "./Pages/Dashboard";
import Dashboard_contractor from "./Pages/Dashboard_contractor";
import ProjectsList from "./Pages/ProjectsList";
import Login from "./Login";
import Register from "./Register";
import Logout from "./Logout";
import AccountSettings from "./Pages/AccountSettings";

class App extends Component {
  state = { isloading: false, user: null };
  blockUI = (isloading) => {
    this.setState({ isloading });
  };
  isLoggedIn = () => {
    if (localStorage.getItem("user") != null) {
      /*if (
        this.state.user == null ||
        this.state.user == "null" ||
        this.state.user == undefined
      ) {
        console.log("I am null");
        this.setState({ user: JSON.parse(localStorage.getItem("user")) });
      }
      console.log("I am not null");
      console.log(this.state.user);*/
      return true;
    }
    return false;
  };
  componentDidMount() {
    this.setState({ user: JSON.parse(localStorage.getItem("user")) });
  }
  loginState = (state) => {
    this.setState({ user: JSON.parse(localStorage.getItem("user")) });
  };
  render() {
    const abc = <Router></Router>;
    return (
      <Spin spinning={this.state.isloading}>
        <Router>
          <Switch>
            <Route
              path="/login"
              render={(props) => (
                <Login
                  {...props}
                  blockUI={this.blockUI}
                  updateLoginState={this.loginState}
                />
              )}
            />
            <Route
              path="/register"
              render={(props) => (
                <Register
                  {...props}
                  blockUI={this.blockUI}
                  updateLoginState={this.loginState}
                />
              )}
            />
            <Route path="/logout" component={Logout} />
            <Route
              path="/"
              render={(props) => {
                if (this.state.user != null) {
                  return (
                    <Switch>
                      <Route
                        path="/jobslist"
                        render={(props) => (
                          <MasterLayout>
                            <JobsList {...props} blockUI={this.blockUI} />
                          </MasterLayout>
                        )}
                      ></Route>
                      <Route
                        path="/jobadd"
                        render={(props) => (
                          <MasterLayout>
                            <JobAdd {...props} blockUI={this.blockUI} />
                          </MasterLayout>
                        )}
                      ></Route>
                      <Route
                        path="/jobedit/:job_id"
                        render={(props) => (
                          <MasterLayout>
                            <JobEdit blockUI={this.blockUI} {...props} />
                          </MasterLayout>
                        )}
                      ></Route>

                      <Route
                        path="/jobview/:job_id"
                        render={(props) => (
                          <MasterLayout>
                            <JobView blockUI={this.blockUI} {...props} />
                          </MasterLayout>
                        )}
                      ></Route>
                      <Route
                        path="/userslist"
                        render={(props) => (
                          <MasterLayout>
                            <UsersList
                              blockUI={this.blockUI}
                              {...props}
                            ></UsersList>
                          </MasterLayout>
                        )}
                      ></Route>
                      <Route
                        path="/contractorslist"
                        render={(props) => (
                          <MasterLayout>
                            <ContractorsList
                              blockUI={this.blockUI}
                              {...props}
                            ></ContractorsList>
                          </MasterLayout>
                        )}
                      ></Route>

                      <Route path="/useradd">
                        <MasterLayout>
                          <UserAdd
                            user={this.state.user}
                            blockUI={this.blockUI}
                          />
                        </MasterLayout>
                      </Route>
                      <Route
                        path="/useredit/:id"
                        render={(props) => (
                          <MasterLayout>
                            <UserEdit
                              {...props}
                              user={this.state.user}
                              blockUI={this.blockUI}
                            />
                          </MasterLayout>
                        )}
                      ></Route>
                      <Route
                        path="/wslist"
                        render={(props) => (
                          <MasterLayout>
                            <WSList {...props} blockUI={this.blockUI}></WSList>
                          </MasterLayout>
                        )}
                      ></Route>
                      <Route
                        path="/mywslist"
                        render={(props) => (
                          <MasterLayout>
                            <MyWSList {...props} blockUI={this.blockUI} />
                          </MasterLayout>
                        )}
                      ></Route>
                      <Route
                        path="/wsedit/:id"
                        render={(props) => (
                          <MasterLayout>
                            <WSEdit {...props} blockUI={this.blockUI}></WSEdit>
                          </MasterLayout>
                        )}
                      ></Route>
                      <Route path="/projectslist">
                        <MasterLayout>
                          <ProjectsList blockUI={this.blockUI}></ProjectsList>
                        </MasterLayout>
                      </Route>
                      <Route
                        path="/account"
                        render={(props) => (
                          <MasterLayout>
                            <AccountSettings
                              {...props}
                              blockUI={this.blockUI}
                            />
                          </MasterLayout>
                        )}
                      ></Route>
                      <Route
                        path="/"
                        render={(props) => (
                          <MasterLayout>
                            {this.state.user.role == "contractor" ? (
                              <Dashboard_contractor
                                {...props}
                                blockUI={this.blockUI}
                              />
                            ) : (
                              <Dashboard {...props} blockUI={this.blockUI} />
                            )}
                          </MasterLayout>
                        )}
                      ></Route>
                    </Switch>
                  );
                } else {
                  return <Redirect to="/login" />;
                }
              }}
            />
          </Switch>
        </Router>
      </Spin>
    );
    return <React.Fragment></React.Fragment>;
  }
}

export default App;
