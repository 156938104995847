import React, { Component } from "react";
import "antd/dist/antd.css";
import { Button, notification } from "antd";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";

const axios = require("axios");

class WSEdit extends Component {
  state = { ws: {} };
  componentDidMount() {
    this.initCom();
  }
  initCom = () => {
    const ws_id = this.props.match.params.id;
    axios
      .get(process.env.REACT_APP_API_URL + "ws/view/" + ws_id)
      .then((resp) => {
        const rrw = resp.data.item;
        this.setState({
          ws: {
            first_name: rrw.first_name,
            last_name: rrw.last_name,
            name: rrw.name,
            contact_name: rrw.contact_name,
            street_address: rrw.street_address,
            city: rrw.city,
            state: rrw.state,
            postal_code: rrw.postal_code,
            country: rrw.country,
            phone_number: rrw.phone_number,
            email_address: rrw.email_address,
          },
        });
      })
      .catch((resp) => {
        let msg = [];
        if (resp.response) {
          resp = resp.response;
          if (resp.status == 422) {
            for (var i in resp.data) {
              msg.push(resp.data[i]);
            }
          }
        }
        notification.error({
          message: "Failed to Edit Wholesaler.",
          description: msg.map((m) => <p>{m}</p>),
          placement: "bottomRight",
          duration: 60,
        });
      })
      .then((resp) => {});
  };
  handleEditingWS = (e) => {
    this.props.blockUI(true);
    axios
      .post(
        process.env.REACT_APP_API_URL +
          "ws/update/" +
          this.props.match.params.id,
        this.state.ws
      )
      .then((resp) => {
        if (resp.data.status)
          notification.success({
            message: "Wholesale Distributor Edited.",
            description:
              "Wholesale Distributor has been edited. It's available for selection in wholesale ditributors dropdown.",
            placement: "bottomRight",
          });
      })
      .catch((resp) => {
        notification.error({
          message: "Failed",
          description: "Failed to Edit Wholesale distributor.",
          placement: "bottomRight",
        });
      })
      .then((resp) => {
        this.props.blockUI(false);
      });
  };
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4>Edit Wholesale Distributor</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label>Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.ws.name}
                        onChange={(e) => {
                          let temp = this.state.ws;
                          temp.name = e.target.value;
                          this.setState({ ws: temp });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label> Contact Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.ws.contact_name}
                        onChange={(e) => {
                          let temp = this.state.ws;
                          temp.contact_name = e.target.value;
                          this.setState({ ws: temp });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label>Phone Number</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.ws.phone_number}
                        onChange={(e) => {
                          let temp = this.state.ws;
                          temp.phone_number = e.target.value;
                          this.setState({ ws: temp });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.ws.email_address}
                        onChange={(e) => {
                          let temp = this.state.ws;
                          temp.email_address = e.target.value;
                          this.setState({ ws: temp });
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group row">
                      <label> City</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.ws.city}
                        onChange={(e) => {
                          let temp = this.state.ws;
                          temp.city = e.target.value;
                          this.setState({ ws: temp });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label> State</label>

                      <RegionDropdown
                        countryValueType="short"
                        country={"US"}
                        value={this.state.ws.state}
                        onChange={(val) => {
                          let temp = this.state.ws;
                          temp.state = val;
                          this.setState({ ws: temp });
                        }}
                        classes="form-control"
                      />
                    </div>
                  </div>
                </div>
                {/*<div className="row">
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label> Company</label>
                      <textarea
                        type="text"
                        className="form-control"
                        value={this.state.ws.company}
                        onChange={(e) => {
                          let temp = this.state.ws;
                          temp.company = e.target.value;
                          this.setState({ ws: temp });
                        }}
                      ></textarea>
                    </div>
                  </div>
                  
                  
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group row">
                      <label> Address</label>
                      <textarea
                        type="text"
                        className="form-control"
                        value={this.state.ws.street_address}
                        onChange={(e) => {
                          let temp = this.state.ws;
                          temp.street_address = e.target.value;
                          this.setState({ ws: temp });
                        }}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label> Zip Code</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.ws.postal_code}
                        onChange={(e) => {
                          let temp = this.state.ws;
                          temp.postal_code = e.target.value;
                          this.setState({ ws: temp });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label>Country</label>
                      
                      <CountryDropdown
                        value={this.state.ws.country}
                        onChange={(val) => {
                          let temp = this.state.ws;
                          temp.country = val;
                          this.setState({ ws: temp });
                        }}
                        priorityOptions={["US"]}
                        classes="form-control"
                      />
                    </div>
                  </div>
                  
                </div>*/}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 ">
            <button
              className="btn btn-success btn-lg"
              onClick={this.handleEditingWS}
            >
              Submit
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default WSEdit;
