import React, { Component } from "react";
import moment from "moment";
import "antd/dist/antd.css";
import {
  Popconfirm,
  notification,
  Upload,
  message,
  Modal,
  Collapse,
  DatePicker,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

import { Image } from "antd";
const { Panel } = Collapse;
const axios = require("axios");

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
class JobAdd extends Component {
  state = {
    show_about_modal: false,
    scheduled_on: null,
    projects_list: [],
    job: {
      name1: "",
      name2: "",
      name3: "",
      scheduled_on: "0000-00-00 00:00:00",
      street_address: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
      description: "",
      contact_name: "",
      phone_number: "",
      email_address: "",
      wholeseller1: 0,
      wholeseller2: 0,
      wholeseller3: 0,
      project_type: 0,
      contractor_id: 0,
      projects: [],
    },
    wholesellers_list: [],
    pjkt_edit_index: -1,
    je_edit_index: -1,
    ws_add: {
      first_name: "",
      last_name: "",
      street_address: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
      phone_number: "",
      email_address: "",
      created_by: 0,
    },
    user: {},
  };
  componentDidMount() {
    //pick user id from localstorage and fill contractor id in column
    let user = JSON.parse(localStorage.getItem("user"));
    let temp = this.state.job;
    temp.contractor_id = user.id;
    temp.state = user.state;
    temp.city = user.city;
    temp.email_address = user.email_address;
    temp.phone_number = user.phone_number;
    //
    this.setState(
      {
        job: temp,
        user: JSON.parse(localStorage.getItem("user")),
        scheduled_on: moment(),
      },
      function () {
        let temp = this.state.job;
        //temp.street_address = this.state.user.street_address;
        //temp.state = this.state.user.state;
        //temp.country = this.state.user.country;
        //temp.city = this.state.user.city;
        this.initComp();
      }
    );
  }
  handleAddingWS = (e) => {
    this.props.blockUI(true);
    let temp = this.state.ws_add;
    temp.created_by = this.state.user.id;
    axios
      .post(process.env.REACT_APP_API_URL + "ws/add", temp)
      .then((resp) => {
        if (resp.data.status) {
          notification.success({
            message: "Wholesale Distributor Added.",
            description:
              "Wholesale Distributor has been added. It's available for selection in wholesale ditributors dropdown.",
            placement: "bottomRight",
          });
        }
      })
      .catch((resp) => {
        let msg = [];
        if (resp.response) {
          resp = resp.response;
          if (resp.status == 422) {
            for (var i in resp.data) {
              msg.push(resp.data[i]);
            }
          }
        }
        notification.error({
          message: "Failed",
          description: msg,
          placement: "bottomRight",
          duration: 60,
        });
      })
      .then((resp) => {
        this.props.blockUI(false);
        this.fetchWholesellersList();
      });
  };
  handleAddJob = (e) => {
    this.props.blockUI(true);
    let obj = this.state.job;
    obj["job_equipment"] = this.state.job_equipment;
    obj["scheduled_on"] = moment().format("YYYY-MM-DD HH:mm:ss");
    console.log("job: ");
    console.log(obj);
    axios
      .post(process.env.REACT_APP_API_URL + "jobs/add_b", obj)
      .then((resp) => {
        if (resp.data.status) {
          notification.success({
            message: "Job Added.",
            description: "Job has been added.",
            placement: "bottomRight",
          });
          this.props.history.push("/");
        }
      })
      .catch((resp) => {
        let msg = [];
        if (resp.response) {
          resp = resp.response;
          if (resp.status == 422) {
            for (var i in resp.data) {
              msg.push(resp.data[i]);
            }
          }
        }
        notification.error({
          message: "Failed to Add Job.",
          description: msg.map((m) => <p>{m}</p>),
          placement: "bottomRight",
          duration: 60,
        });
      })
      .then((resp) => {
        this.props.blockUI(false);
      });
  };
  beforeUpload = (file, i, ji, img_num) => {
    //console.log(file.originFileObj);
    //return false;
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    getBase64(file, (imageUrl) => {
      console.log(imageUrl);
      let job = this.state.job;
      job.projects[i].job_equipment[ji][img_num] = imageUrl;
      switch (img_num) {
        case "img1_path":
          job.projects[i].job_equipment[ji].loading1 = false;
          break;
        case "img2_path":
          job.projects[i].job_equipment[ji].loading2 = false;
          break;
        case "img3_path":
          job.projects[i].job_equipment[ji].loading3 = false;
          break;
      }

      this.setState(job);
    });
    return false;
  };
  addJobEquipment = (e, i) => {
    let job = this.state.job;

    job.projects[i].job_equipment.push({
      project_equipment_id: 0,
      name: "",
      size: "",
      price: 0,
      qty: 0,
      description: "",
      mode_edit: true,
      img1_path: "",
      img2_path: "",
      img3_path: "",
      loading1: false,
      loading2: false,
      loading3: false,
    });
    this.setState(job);
  };
  initComp() {
    //fetch and fill projects list dropdown
    axios
      .get(process.env.REACT_APP_API_URL + "projects/list")
      .then((resp) => {
        this.setState({ projects_list: resp.data.items });
      })
      .catch((err) => {
        console.error(err);
      })
      .then((any) => {});
    //
    this.fetchWholesellersList();
  }
  fetchWholesellersList = () => {
    //fetch and fill Wholesellers list dropdown
    axios
      .get(process.env.REACT_APP_API_URL + "ws/list/" + this.state.user.id)
      .then((resp) => {
        this.setState({ wholesellers_list: resp.data.items });
      })
      .catch((err) => {
        console.error(err);
      })
      .then((any) => {});
    //
  };
  handleProjectTypeChange = (e, index) => {
    this.props.blockUI(true);
    let job = this.state.job;
    job.project_type = e.target.value;

    axios
      .get(
        process.env.REACT_APP_API_URL +
          "projects/view/" +
          this.state.job.project_type
      )
      .then((data) => {
        try {
          job.projects[index].job_equipment = [];
          job.projects[index].project_id = job.project_type;
          let jes = data.data.equipments;
          let temp_jes = [];
          for (var i = 0; i < jes.length; i++) {
            let j_e = {
              project_equipment_id: jes[i].id,
              name: jes[i].name,
              size: jes[i].size,
              price: 0,
              qty: 0,
              description: jes[i].description,
              mode_edit: true,
              img1_path: "",
              img2_path: "",
              img3_path: "",
              loading1: false,
              loading2: false,
              loading3: false,
            };
            job.projects[index].job_equipment.push(j_e);
          }

          this.setState(job);
        } catch (e) {
          console.log(e);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .then((resp) => {
        this.props.blockUI(false);
      });
  };

  removeJobProject = (e, i) => {
    let job = this.state.job;
    job.projects.splice(i, 1);
    this.setState(job);
  };
  editJobEquipment = (e, i, ji) => {
    let job = this.state.job;
    job.projects[i].job_equipment[ji].mode_edit = !job.projects[i]
      .job_equipment[ji].mode_edit;
    this.setState(job);
  };
  removeJobEquipment = (e, i, ji) => {
    let job = this.state.job;
    job.projects[i].job_equipment.splice(ji, 1);
    this.setState(job);
  };
  handleJobEquipmentChange = (e, i, ji, key) => {
    let job = this.state.job;
    job.projects[i].job_equipment[ji][key] = e.target.value;
    this.setState(job);
  };
  addAnotherProject = (e) => {
    let job = this.state.job;
    job.projects.push({
      id: 0,
      project_id: 0,
      job_equipment: [],
    });
    this.setState({ job, pjkt_edit_index: job.projects.length - 1 });
  };
  toggleModal = (visibility) => {
    this.setState({ show_about_modal: visibility });
  };
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="p-4 border-bottom bg-light">
                  <h4
                    className="card-title"
                    style={{ width: "70%", display: "inline" }}
                  >
                    Add New Job
                  </h4>
                  {/************watt o expect button */}
                  <p
                    className="card-description"
                    style={{ float: "right", display: "inline" }}
                  >
                    <button
                      className="btn btn-info pull-right"
                      style={{ backgroundColor: "#102EB4" }}
                      onClick={(e) => {
                        Modal.info({
                          title: "What To Expect",
                          content: (
                            <div>
                              <p>
                                Welcome to CONTRACTORBOM.COM, your source for
                                complete and accurate bill of materials and
                                costs for your next commercial project. To
                                ensure our product costs are accurate and
                                reliable, we select top manufacturing brands
                                that we have direct relationships with.
                                Contractor BOM has over 100 years’ experience
                                sizing and selecting HVAC system solutions for
                                the trades. A complete and accurate Bill of
                                Material (BOM), containing products with
                                applicable part numbers will be delivered to you
                                within 4 hours. Additionally, a maximum (budget)
                                acquisition cost for the material list will be
                                included. If you have selected your BOM to be
                                sent to a designated wholesale distributor, we
                                will do so at the same time we send the BOM to
                                you. We will call you if we have any questions
                                or need clarification on your sections. If you
                                need assistance before 4 hours, please click
                                here and an agent will call you back shortly, or
                                you can call us at 800.932.4454
                              </p>
                            </div>
                          ),
                          onOk() {},
                        });
                      }}
                    >
                      WHAT TO EXPECT{" "}
                    </button>
                  </p>
                  {/***************************************** */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <form
                  className="form-sample"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Project Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.job.name1}
                          onChange={(e) => {
                            let temp = this.state.job;
                            temp.name1 = e.target.value;
                            this.setState({ job: temp });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Date Time</label>
                        <DatePicker
                          className="form-control"
                          defaultValue={moment()}
                          format="MM-DD-YYYY h:mm a"
                          style={{
                            borderColor: "gray !important",
                            paddingTop: "5px",
                          }}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Job Address</label>
                        <textarea
                          type="text"
                          className="form-control"
                          value={this.state.job.street_address}
                          onChange={(e) => {
                            let temp = this.state.job;
                            temp.street_address = e.target.value;
                            this.setState({ job: temp });
                          }}
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Description | Notes (Optional) </label>
                        <textarea
                          className="form-control"
                          value={this.state.job.description}
                          onChange={(e) => {
                            let temp = this.state.job;
                            temp.description = e.target.value;
                            this.setState({ job: temp });
                          }}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/*Adding job equipmetns*/}
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h5>Projects</h5>
                <p
                  style={{
                    color: "blue",
                    marginBottom: "15px",
                    lineHeight: "17px",
                  }}
                >
                  <small>
                    Enter equipment information based on current conditions.
                    Take pics of name plates and identifying equipment markings
                    | tags. Circulator pumps: take pics of identifying tags for
                    pump, volute, and motor
                  </small>
                </p>
                {/**************project type */}
                {this.state.job.projects.map((project, i) => (
                  <React.Fragment>
                    <hr />
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <div className="row">
                            <label className="col-md-6">
                              SELECT PRODUCT TYPE &nbsp;
                              <Popconfirm
                                title="Are you sure?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={(e) => this.removeJobProject(e, i)}
                              >
                                <button className="btn btn-sm btn-outline-danger">
                                  <i className="fa fa-trash"></i>
                                </button>
                              </Popconfirm>
                            </label>
                            <div className="col-md-6">
                              <select
                                className="form-control"
                                value={
                                  this.state.job.projects[i].project_id !==
                                  undefined
                                    ? this.state.job.projects[i].project_id
                                    : ""
                                }
                                onChange={(e) =>
                                  this.handleProjectTypeChange(e, i)
                                }
                              >
                                <option value="0">Select Product Type</option>
                                {this.state.projects_list.map((pj) => (
                                  <option key={pj.id} value={pj.id}>
                                    {pj.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ overflow: "auto" }}>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Notes</th>
                            <th>Size</th>
                            <th>Quantity</th>
                            {/*<th>Price</th>*/}
                            <th>Image 1</th>
                            <th>Image 2</th>
                            <th>Image 3</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.job.projects[i].job_equipment !==
                          undefined
                            ? this.state.job.projects[i].job_equipment.map(
                                (je, ji) => (
                                  <tr key={ji}>
                                    <td>
                                      {je.mode_edit == true ? (
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={je.name}
                                          onChange={(e) =>
                                            this.handleJobEquipmentChange(
                                              e,
                                              i,
                                              ji,
                                              "name"
                                            )
                                          }
                                        />
                                      ) : (
                                        <p>{je.name}</p>
                                      )}
                                    </td>
                                    <td>
                                      {je.mode_edit == true ? (
                                        <input
                                          className="form-control"
                                          type="text"
                                          value={je.description}
                                          onChange={(e) =>
                                            this.handleJobEquipmentChange(
                                              e,
                                              i,
                                              ji,
                                              "description"
                                            )
                                          }
                                        />
                                      ) : (
                                        <p>{je.description}</p>
                                      )}
                                    </td>
                                    <td>
                                      {je.mode_edit == true ? (
                                        <input
                                          className="form-control"
                                          type="text"
                                          value={je.size}
                                          onChange={(e) =>
                                            this.handleJobEquipmentChange(
                                              e,
                                              i,
                                              ji,
                                              "size"
                                            )
                                          }
                                        />
                                      ) : (
                                        <p>{je.size}</p>
                                      )}
                                    </td>
                                    <td>
                                      {je.mode_edit == true ? (
                                        <input
                                          className="form-control"
                                          type="text"
                                          value={je.qty}
                                          onChange={(e) =>
                                            this.handleJobEquipmentChange(
                                              e,
                                              i,
                                              ji,
                                              "qty"
                                            )
                                          }
                                        />
                                      ) : (
                                        <p>{je.qty}</p>
                                      )}
                                    </td>
                                    <td>
                                      {je.mode_edit == true ? (
                                        <Upload
                                          name="avatar"
                                          listType="picture-card"
                                          className="avatar-uploader"
                                          showUploadList={false}
                                          beforeUpload={(file) =>
                                            this.beforeUpload(
                                              file,
                                              i,
                                              ji,
                                              "img1_path"
                                            )
                                          }
                                        >
                                          {je.img1_path ? (
                                            <img
                                              src={je.img1_path}
                                              alt="avatar"
                                              style={{ width: "100%" }}
                                            />
                                          ) : (
                                            <div>
                                              {je.loading1 ? (
                                                <LoadingOutlined />
                                              ) : (
                                                <PlusOutlined />
                                              )}
                                              <div style={{ marginTop: 8 }}>
                                                Upload
                                              </div>
                                            </div>
                                          )}
                                        </Upload>
                                      ) : (
                                        <Image src={je.img1_path} />
                                      )}
                                    </td>
                                    <td>
                                      {je.mode_edit == true ? (
                                        <Upload
                                          name="avatar"
                                          listType="picture-card"
                                          className="avatar-uploader"
                                          showUploadList={false}
                                          beforeUpload={(file) =>
                                            this.beforeUpload(
                                              file,
                                              i,
                                              ji,
                                              "img2_path"
                                            )
                                          }
                                        >
                                          {je.img2_path ? (
                                            <img
                                              src={je.img2_path}
                                              alt="avatar"
                                              style={{ width: "100%" }}
                                            />
                                          ) : (
                                            <div>
                                              {je.loading2 ? (
                                                <LoadingOutlined />
                                              ) : (
                                                <PlusOutlined />
                                              )}
                                              <div style={{ marginTop: 8 }}>
                                                Upload
                                              </div>
                                            </div>
                                          )}
                                        </Upload>
                                      ) : (
                                        <Image src={je.img2_path} />
                                      )}
                                    </td>
                                    <td>
                                      {je.mode_edit == true ? (
                                        <Upload
                                          name="avatar"
                                          listType="picture-card"
                                          className="avatar-uploader"
                                          showUploadList={false}
                                          beforeUpload={(file) =>
                                            this.beforeUpload(
                                              file,
                                              i,
                                              ji,
                                              "img3_path"
                                            )
                                          }
                                        >
                                          {je.img3_path ? (
                                            <img
                                              src={je.img3_path}
                                              alt="avatar"
                                              style={{ width: "100%" }}
                                            />
                                          ) : (
                                            <div>
                                              {je.loading3 ? (
                                                <LoadingOutlined />
                                              ) : (
                                                <PlusOutlined />
                                              )}
                                              <div style={{ marginTop: 8 }}>
                                                Upload
                                              </div>
                                            </div>
                                          )}
                                        </Upload>
                                      ) : (
                                        <Image src={je.img3_path} />
                                      )}
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-sm btn-outline-warning"
                                        onClick={(e) =>
                                          this.editJobEquipment(e, i, ji)
                                        }
                                      >
                                        <i
                                          className={
                                            je.mode_edit == true
                                              ? "fa fa-check"
                                              : "fa fa-pencil"
                                          }
                                        ></i>
                                      </button>
                                      &nbsp;
                                      <Popconfirm
                                        title="Are you sure delete this Equipment?"
                                        okText="Yes"
                                        cancelText="No"
                                        onConfirm={(e) =>
                                          this.removeJobEquipment(e, i, ji)
                                        }
                                        className="btn btn-sm btn-outline-danger"
                                      >
                                        <button className="btn btn-sm btn-outline-danger">
                                          <i className="fa fa-trash"></i>
                                        </button>
                                      </Popconfirm>
                                    </td>
                                  </tr>
                                )
                              )
                            : ""}
                        </tbody>
                      </table>
                    </div>
                    <div style={{ display: "inline-block", width: "100%" }}>
                      <div className="col-md-12">
                        <button
                          type="button"
                          className="btn btn-outline-info"
                          style={{
                            marginLeft: "-12px",
                            marginTop: "7px",
                            backgroundColor: "#102EB4",
                            color: "white",
                          }}
                          onClick={(e) => this.addJobEquipment(e, i)}
                        >
                          Add Job Equipment
                        </button>
                      </div>
                    </div>
                  </React.Fragment>
                ))}

                {/*********************************************************ABOVE IS A WHOLE PROJECT */}
                <div>
                  <button
                    type="button"
                    className="btn btn-outline-info"
                    style={{
                      marginRight: "-12px",
                      marginTop: "15px",
                      marginBottom: "-15px",
                      backgroundColor: "#102EB4",
                      color: "white",
                    }}
                    onClick={this.addAnotherProject}
                  >
                    {this.state.job.projects.length == 0
                      ? "Add Product Type"
                      : "Add Another Product Type"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/***** */}
        {/***WholeSale distributors section */}
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h5>
                  Select a wholesale distributor to send BOM to (Optional).
                  <small>
                    {" "}
                    To add or edit your wholesale distributor please visit My
                    Wholesalers section.
                  </small>
                </h5>
                <hr />
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Wholesale Distributor 1</label>
                      <select
                        className="form-control"
                        value={this.state.job.wholeseller1}
                        onChange={(e) => {
                          let job = this.state.job;
                          job.wholeseller1 = e.target.value;
                          this.setState({ job });
                        }}
                      >
                        <option value="0"></option>
                        {this.state.wholesellers_list.map((ws) => (
                          <option key={ws.id} value={ws.id}>
                            {ws.name + " (" + ws.id + ")"}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Wholesale Distributor 2</label>
                      <select
                        className="form-control"
                        value={this.state.job.wholeseller2}
                        onChange={(e) => {
                          let job = this.state.job;
                          job.wholeseller2 = e.target.value;
                          this.setState({ job });
                        }}
                      >
                        <option value="0"></option>
                        {this.state.wholesellers_list.map((ws) => (
                          <option key={ws.id} value={ws.id}>
                            {ws.name + " (" + ws.id + ")"}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Wholesale Distributor 3</label>
                      <select
                        className="form-control"
                        value={this.state.job.wholeseller3}
                        onChange={(e) => {
                          let job = this.state.job;
                          job.wholeseller3 = e.target.value;
                          this.setState({ job });
                        }}
                      >
                        <option value="0"></option>
                        {this.state.wholesellers_list.map((ws) => (
                          <option key={ws.id} value={ws.id}>
                            {ws.name + " (" + ws.id + ")"}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {this.state.wholesellers_list.length < 0 ? (
                  <Collapse
                    style={{
                      marginLeft: "-13px",
                      marginTop: "0px",
                      marginRight: "-13px",
                    }}
                  >
                    <Panel header="Add New Wholesale Distributor">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Name</label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.ws_add.name}
                              onChange={(e) => {
                                let temp = this.state.ws_add;
                                temp.name = e.target.value;
                                this.setState({ ws_add: temp });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label> Contact Name</label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.ws_add.contact_name}
                              onChange={(e) => {
                                let temp = this.state.ws_add;
                                temp.contact_name = e.target.value;
                                this.setState({ ws_add: temp });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Phone Number</label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.ws_add.phone_number}
                              onChange={(e) => {
                                let temp = this.state.ws_add;
                                temp.phone_number = e.target.value;
                                this.setState({ ws_add: temp });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Email</label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.ws_add.email_address}
                              onChange={(e) => {
                                let temp = this.state.ws_add;
                                temp.email_address = e.target.value;
                                this.setState({ ws_add: temp });
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <button
                            style={{ backgroundColor: "#102EB4" }}
                            className="btn btn-outline-info pull-right"
                            onClick={this.handleAddingWS}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </Panel>
                  </Collapse>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        {/******************************************* */}
        <div className="row">
          <div className="col-md-12">
            <button
              style={{ backgroundColor: "#102EB4", border: "0" }}
              className="btn btn-lg btn-success"
              type="button"
              onClick={this.handleAddJob}
            >
              Submit
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default JobAdd;
