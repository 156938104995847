import React, { Component } from "react";
import { Link } from "react-router-dom";

import MasterLayout from "./MasterLayout";
class MenuLayout extends Component {
  state = { user: null, com_active: true };
  componentDidMount() {
    this.lookforuserInfo();
  }
  lookforuserInfo() {
    if (!this.state.com_active) return;
    if (localStorage.getItem("user") != null)
      this.setState({ user: JSON.parse(localStorage.getItem("user")) });
    setTimeout((e) => {
      this.lookforuserInfo();
    }, 5000);
  }
  componentWillUnmount() {
    this.setState({ com_active: false });
  }
  render() {
    if (this.state.user != null)
      return (
        <nav className="sidebar sidebar-offcanvas" id="sidebar">
          <ul className="nav">
            <li className="nav-item nav-profile">
              <a href="#" className="nav-link">
                <div className="profile-image">
                  <img
                    className="img-xs rounded-circle"
                    src={this.state.user.profile_pic}
                    alt="profile image"
                  />
                  <div className="dot-indicator bg-success"></div>
                </div>
                <div className="text-wrapper">
                  <p className="profile-name">
                    {this.state.user.first_name +
                      " " +
                      this.state.user.last_name}
                  </p>
                  <p className="designation">
                    {this.state.user.role == "wallace_staff"
                      ? "ContractorBOM Staff"
                      : this.state.user.role}
                  </p>
                </div>
              </a>
            </li>
            <li className="nav-item nav-category">Main Menu</li>
            <li className="nav-item">
              <Link to="/" className="nav-link">
                <i className="menu-icon typcn typcn-document-text"></i>
                <span className="menu-title">Dashboard</span>
              </Link>
            </li>
            {this.state.user.role !== "contractor" ? (
              <React.Fragment>
                <li className="nav-item">
                  <Link to="/jobslist" className="nav-link">
                    <i className="menu-icon typcn typcn-document-text"></i>
                    <span className="menu-title">BOMs Sent</span>
                  </Link>
                </li>

                {this.state.user.role === "superadmin" ? (
                  <li className="nav-item">
                    <Link to="/userslist" className="nav-link">
                      <i className="menu-icon typcn typcn-document-text"></i>
                      <span className="menu-title">Users</span>
                    </Link>
                  </li>
                ) : (
                  <li className="nav-item">
                    <Link to="/contractorslist" className="nav-link">
                      <i className="menu-icon typcn typcn-document-text"></i>
                      <span className="menu-title">Contractors</span>
                    </Link>
                  </li>
                )}
                <li className="nav-item">
                  <Link to="/projectslist" className="nav-link">
                    <i className="menu-icon typcn typcn-document-text"></i>
                    <span className="menu-title">Project Types</span>
                  </Link>
                </li>
                {/*<li className="nav-item">
                  <Link to="/wslist" className="nav-link">
                    <i className="menu-icon typcn typcn-document-text"></i>
                    <span className="menu-title">Wholesalers</span>
                  </Link>
                </li>*/}
              </React.Fragment>
            ) : (
              <li className="nav-item">
                <Link to="/mywslist" className="nav-link">
                  <i className="menu-icon typcn typcn-document-text"></i>
                  <span className="menu-title">My Wholesalers</span>
                </Link>
              </li>
            )}
            <hr />
            <li className="nav-item">
              <Link to="/account" className="nav-link">
                <i className="menu-icon typcn typcn-document-text"></i>
                <span className="menu-title">My Account</span>
              </Link>
            </li>
          </ul>
        </nav>
      );
    return <React.Fragment></React.Fragment>;
  }
}

export default MenuLayout;
