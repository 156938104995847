import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, notification } from "antd";
const axios = require("axios");
class Login extends Component {
  state = {
    user: [],
    login_creds: { email_address: "", password: "" },
    isModalresetpassVisible: false,
  };
  handlePassReset = (e) => {
    this.props.blockUI(true);
    axios
      .post(process.env.REACT_APP_API_URL + "reset_my_password", {
        email_address: this.state.login_creds.email_address,
      })
      .then((resp) => {
        console.log(resp);
        if (resp.data.status == "success") {
          notification.success({
            message: "Reset Password Sent.",
            description:
              "An Email been sent to your mentioned Email address, if it has a linked account. Please use the included password to login to your account.",
            placement: "bottomRight",
          });
        } else {
          notification.error({
            message: "Failed",
            description: "An error Occured",
            placement: "bottomRight",
            duration: 60,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        notification.error({
          message: "Failed",
          description: "An error Occured",
          placement: "bottomRight",
          duration: 60,
        });
      })
      .then((e) => {
        this.props.blockUI(false);
      });
    this.setState({ isModalresetpassVisible: false });
  };
  componentDidMount() {
    if (localStorage.getItem("user") != null) {
      this.props.updateLoginState(true);
      this.props.history.push("/");
    }
  }
  handleLogin = () => {
    this.props.blockUI(true);
    axios
      .post(process.env.REACT_APP_API_URL + "login", this.state.login_creds)
      .then((resp) => {
        console.log(resp.data);
        if (resp.data.status == "success") {
          this.setState({
            user: resp.data.user,
          });
          localStorage.setItem("user", JSON.stringify(resp.data.user));
          this.props.updateLoginState(true);
          this.props.history.push("/");
        } else {
          notification.error({
            message: "Failed",
            description: "An error Occured",
            placement: "bottomRight",
            duration: 60,
          });
        }
      })
      .catch((resp) => {
        notification.error({
          message: "Failed",
          description: "An error Occured",
          placement: "bottomRight",
          duration: 60,
        });
      })
      .then((resp) => {
        this.props.blockUI(false);
      });
  };

  render() {
    return (
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth auth-bg-1 theme-one">
            <div className="row w-100">
              <div className="col-md-12 text-center">
                <img src="./assets/images/auth/logo.png" />
              </div>
              <div className="col-lg-4 mx-auto">
                <div className="auto-form-wrapper">
                  <form
                    action="#"
                    onSubmit={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  >
                    <div className="form-group">
                      <label className="label">Email Address</label>

                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email Address"
                        value={this.state.login_creds.email_address}
                        onChange={(e) => {
                          let temp = this.state.login_creds;
                          temp.email_address = e.target.value;
                          this.setState({ login_creds: temp });
                        }}
                        style={{ height: "50px", borderRadius: "0.4em" }}
                      />
                    </div>
                    <div className="form-group">
                      <label className="label">Password</label>

                      <input
                        type="password"
                        className="form-control"
                        placeholder="*********"
                        style={{ height: "50px", borderRadius: "0.4em" }}
                        value={this.state.login_creds.password}
                        onChange={(e) => {
                          let temp = this.state.login_creds;
                          temp.password = e.target.value;
                          this.setState({ login_creds: temp });
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <button
                        onClick={this.handleLogin}
                        className="btn btn-primary submit-btn btn-block"
                      >
                        Login
                      </button>
                    </div>
                    <div className="form-group d-flex justify-content-between">
                      <div className="form-check form-check-flat mt-0"></div>
                      <Button
                        onClick={(e) => {
                          this.setState({ isModalresetpassVisible: true });
                        }}
                        class="text-small forgot-password text-black"
                      >
                        Forgot Password
                      </Button>
                    </div>
                    <div className="text-block text-center my-3">
                      <span className="text-small font-weight-semibold">
                        Not a member ?&nbsp;
                      </span>
                      <Link to="/register" className="text-black text-small">
                        Create new account
                      </Link>
                    </div>
                  </form>
                </div>

                <p className="footer-text text-center">
                  copyright © 2020{" "}
                  <a href="http://www.ybgmarketing.com" target="_blank">
                    YBG Marketing
                  </a>
                  . All rights reserved.
                </p>
              </div>
            </div>
          </div>
          {/*<!-- content-wrapper ends -->*/}
        </div>
        {/*<!-- page-body-wrapper ends -->*/}
        <Modal
          title="Reset Password"
          visible={this.state.isModalresetpassVisible}
          onOk={this.handlePassReset}
          onCancel={(e) => {
            this.setState({ isModalresetpassVisible: false });
          }}
        >
          <p>
            Enter your email to get a new password in your email, which you can
            use to login and then change your password.
          </p>
          <input
            placeholder="Email Address"
            value={this.state.login_creds.email_address}
            onChange={(e) => {
              let temp = this.state.login_creds;
              temp.email_address = e.target.value;
              this.setState({ login_creds: temp });
            }}
            type="text"
            className="form-control"
          />
        </Modal>
      </div>
    );
  }
}

export default Login;
