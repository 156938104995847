import React, { Component } from "react";
import { Table, Empty } from "antd";
const axios = require("axios");
class UsersList extends Component {
  state = {
    data: [],
    loading: false,
    pagination: {
      total: 0,
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
      defaultPageSize: 10,
      defaultCurrent: 1,
      pageSize: 10,
      current: 1,
      showQuickJumper: true,
      showSizeChanger: true,
    },
    sorting: {},
    global_search: "",
  };
  componentDidMount() {
    this.fillInTheTable();
  }
  //Our method to bring in leads list
  fillInTheTable = (params = {}) => {
    this.setState({ loading: true });
    axios
      .post(process.env.REACT_APP_API_URL + "users/listad", {
        ...params,
        global_search: this.state.global_search,
      })
      .then((resp) => {
        console.log(resp);
        let data = resp.data;
        this.setState({
          loading: false,
          data: data.results,
          pagination: {
            ...params.pagination,
            total: data.pagination.total,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            showQuickJumper: true,
            showSizeChanger: true,
          },
          sortField: data.sortField,
          sortOrder: data.sortOrder,
        });
      })
      .catch((resp) => {
        console.log(resp);
      })
      .then((resp) => {
        this.setState({ loading: false });
      });
  };
  searchDatatable = (e) => {
    this.setState(
      {
        global_search: e.target.value,
      },
      function () {
        this.fillInTheTable({
          global_search: this.state.global_search,
        });
      }
    );
  };
  //our method to handle table change
  handleTableChange = (pagination, filters, sorter) => {
    this.setState({ pagination: pagination, sorting: sorter });
    this.fillInTheTable({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
    });
  };
  /*********************************** */
  onAddClick = (e) => {
    this.props.history.push("/useradd");
  };
  handleEdit = (e, id) => {
    this.props.history.push("/useredit/" + id);
  };
  render() {
    return (
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body" style={{ paddingBottom: "5px" }}>
              <div className="p-4 border-bottom bg-light">
                <h4
                  className="card-title"
                  style={{ width: "70%", display: "inline" }}
                >
                  Users | Add a User &nbsp;
                  <small>
                    <button
                      className="btn btn-outline-success btn-rounded"
                      onClick={this.onAddClick}
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </small>
                </h4>

                <p
                  className="card-description"
                  style={{ float: "right", display: "inline" }}
                >
                  <input
                    onChange={this.searchDatatable}
                    value={this.state.global_search}
                    className="form-control"
                    placeholder="Search Here"
                  />{" "}
                </p>
              </div>
              <Table
                className="table-striped-rows"
                size={"small"}
                tableLayout={"auto"}
                columns={[
                  {
                    title: "Firm Name",
                    dataIndex: "company",
                    sorter: true,
                  },
                  {
                    title: "Contact First Name",
                    dataIndex: "first_name",
                    sorter: true,
                  },
                  {
                    title: "Contact Last Name",
                    dataIndex: "last_name",
                    sorter: true,
                  },
                  {
                    title: "City",
                    dataIndex: "city",
                    sorter: true,
                  },
                  {
                    title: "State",
                    dataIndex: "state",
                    sorter: true,
                  },
                  {
                    title: "Phone Number",
                    dataIndex: "phone_number",
                    sorter: true,
                  },
                  {
                    title: "Email Address",
                    dataIndex: "email_address",
                    sorter: true,
                  },
                  {
                    title: "Role",
                    dataIndex: "role",
                    sorter: true,
                  },
                  {
                    title: "Total BOMS",
                    dataIndex: "total_boms",
                    sorter: true,
                  },
                  {
                    title: "submitted",
                    dataIndex: "submitted_boms",
                    sorter: true,
                  },
                  {
                    title: "In Review",
                    dataIndex: "in_review_boms",
                    sorter: true,
                  },
                  {
                    title: "Completed",
                    dataIndex: "completed_boms",
                    sorter: true,
                  },
                  {
                    title: "Actions",
                    dataIndex: "action",
                    render: (text, record, index) => {
                      return (
                        <React.Fragment>
                          {/*<button
                            className="btn btn-warning"
                            onClick={(e) => {
                              this.handleEdit(e, record.id);
                            }}
                          >
                            <i className="fa fa-edit"></i>
                          </button>*/}
                          <button
                            className="btn btn-outline-warning"
                            onClick={(e) => {
                              this.handleEdit(e, record.id);
                            }}
                          >
                            <i className="fa fa-pencil"></i>
                          </button>
                        </React.Fragment>
                      );
                    },
                  },
                ]}
                dataSource={this.state.data}
                loading={this.state.loading}
                pagination={this.state.pagination}
                onChange={this.handleTableChange}
                scroll={{ x: true }}
                locale={{
                  emptyText: (
                    <Empty description="No User Groups Created"></Empty>
                  ),
                }}
              ></Table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UsersList;
