import { notification, Popconfirm } from "antd";
import React, { Component } from "react";
import { Table } from "antd";
const axios = require("axios");
class Dashboard_contractor extends Component {
  state = {
    user: null,
    jobs_total: 0,
    jobs_completed: 0,
    jobs_forwarded: 0,
    jobs_pending: 0,
    /************ */
    data: [],
    loading: false,
    pagination: {
      total: 0,
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
      defaultPageSize: 10,
      defaultCurrent: 1,
      pageSize: 10,
      current: 1,
      showQuickJumper: true,
      showSizeChanger: true,
    },
    sorting: {},
    global_search: "",
  };
  componentDidMount() {
    if (localStorage.getItem("user") != null)
      this.setState(
        { user: JSON.parse(localStorage.getItem("user")) },
        function () {
          this.fetchStats();
          this.fillInTheTable();
        }
      );
    console.log("contractor");
  }
  //Our method to bring in leads list
  fillInTheTable = (params = {}) => {
    this.setState({ loading: true });
    axios
      .post(
        process.env.REACT_APP_API_URL + "jobs/listad/" + this.state.user.id,
        {
          ...params,
          global_search: this.state.global_search,
        }
      )
      .then((resp) => {
        console.log(resp);
        let data = resp.data;
        this.setState({
          loading: false,
          data: data.results,
          pagination: {
            ...params.pagination,
            total: data.pagination.total,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            showQuickJumper: true,
            showSizeChanger: true,
          },
          sortField: data.sortField,
          sortOrder: data.sortOrder,
        });
      })
      .catch((resp) => {
        console.log(resp);
      })
      .then((resp) => {
        this.setState({ loading: false });
      });
  };
  searchDatatable = (e) => {
    this.setState(
      {
        global_search: e.target.value,
      },
      function () {
        this.fillInTheTable({
          global_search: this.state.global_search,
        });
      }
    );
  };
  //our method to handle table change
  handleTableChange = (pagination, filters, sorter) => {
    this.setState({ pagination: pagination, sorting: sorter });
    this.fillInTheTable({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
    });
  };
  /*********************************** */
  handleJobListEdit = (e, job_id) => {
    this.props.history.push("/jobedit/" + job_id);
  };
  /*handleJobListView = (e, job_id) => {
    this.props.history.push("/jobview/" + job_id);
  };*/
  fetchStats = () => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "users/contractorstats/" +
          this.state.user.id
      )
      .then((resp) => {
        this.setState({
          jobs_total: resp.data.total_jobs,
          jobs_completed: resp.data.completed_jobs,
          jobs_forwarded: resp.data.forwarded_jobs,
          jobs_pending: resp.data.pending_jobs,
        });
      })
      .catch((resp) => {})
      .then((resp) => {});
  };
  handleJobDelete = (e, id) => {
    this.props.blockUI(true);
    axios
      .get(process.env.REACT_APP_API_URL + "jobs/delete/" + id)
      .then((resp) => {
        if (resp.data.status == true) this.fillInTheTable();
        notification.success({
          message: "Job Removed.",
          description: "Job has been removed successfully.",
          placement: "bottomRight",
        });
      })
      .catch((resp) => {})
      .then((resp) => {
        this.props.blockUI(false);
      });
  };
  actions = (id) => {
    return (
      <React.Fragment>
        <button
          className="btn btn-outline-warning"
          onClick={(e) => {
            this.handleJobListEdit(e, id);
          }}
        >
          <i className="fa fa-pencil"></i>
        </button>
        <Popconfirm
          onConfirm={(e) => {
            this.handleJobDelete(e, id);
          }}
        >
          <button className="btn btn-outline-danger">
            <i className="fa fa-trash"></i>
          </button>
        </Popconfirm>
      </React.Fragment>
    );
  };
  render() {
    return (
      <React.Fragment>
        {/*Stat Widgets*/}
        <div className="row">
          <div className="col-md-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  {/*<div className="col-lg-3 col-md-6">
                    <div className="d-flex">
                      <div className="wrapper">
                        <h3 className="mb-0 font-weight-semibold">
                          {this.state.jobs_total}
                        </h3>
                        <h5 className="mb-0 font-weight-medium text-primary">
                          Total BOMs
                        </h5>
                        <p className="mb-0 text-muted"></p>
                      </div>
                    </div>
                  </div>*/}
                  <div className="col-lg-4 col-md-6 mt-md-0 mt-4">
                    <div className="d-flex">
                      <div className="wrapper">
                        <h3 className="mb-0 font-weight-semibold">
                          {this.state.jobs_pending}
                        </h3>
                        <h5 className="mb-0 font-weight-medium text-primary">
                          Submitted
                        </h5>
                        <p className="mb-0 text-muted"></p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-md-0 mt-4">
                    <div className="d-flex">
                      <div className="wrapper">
                        <h3 className="mb-0 font-weight-semibold">
                          {this.state.jobs_completed}
                        </h3>
                        <h5 className="mb-0 font-weight-medium text-primary">
                          In Review
                        </h5>
                        <p className="mb-0 text-muted"></p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 mt-md-0 mt-4">
                    <div className="d-flex">
                      <div className="wrapper">
                        <h3 className="mb-0 font-weight-semibold">
                          {this.state.jobs_forwarded}
                        </h3>
                        <h5 className="mb-0 font-weight-medium text-primary">
                          Complete
                        </h5>
                        <p className="mb-0 text-muted"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/***************** */}
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            {this.state.user != null ? (
              <div className="card">
                <div className="card-body" style={{ paddingBottom: "5px" }}>
                  <div className="p-4 border-bottom bg-light">
                    <h4
                      className="card-title"
                      style={{ width: "70%", display: "inline" }}
                    >
                      Jobs | Add a Job &nbsp;
                      <small>
                        <button
                          className="btn btn-outline-success btn-rounded"
                          onClick={(e) => {
                            this.props.history.push("/jobadd");
                          }}
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </small>
                    </h4>

                    <p
                      className="card-description"
                      style={{ float: "right", display: "inline" }}
                    >
                      <input
                        onChange={this.searchDatatable}
                        value={this.state.global_search}
                        className="form-control"
                        placeholder="Search Here"
                      />{" "}
                    </p>
                  </div>
                  <Table
                    className="table-striped-rows"
                    size={"small"}
                    tableLayout={"auto"}
                    columns={[
                      {
                        title: "Job  ID",
                        dataIndex: "id",
                        sorter: true,
                      },
                      {
                        title: "Job Name",
                        dataIndex: "name1",
                        sorter: true,
                      },
                      {
                        title: "Date",
                        dataIndex: "date",
                        sorter: true,
                      },
                      {
                        title: "Status",
                        dataIndex: "status",
                        sorter: true,
                        render: (text) => {
                          if (text == "pending") return "Submitted";
                          if (text == "forwarded") return "In Review";
                          else if (text == "completed") return "Complete";
                        },
                      },
                      {
                        title: "Actions",
                        dataIndex: "action",
                        render: (text, record, index) => {
                          return (
                            <React.Fragment>
                              {/*<button
                                className="btn btn-outline-warning"
                                onClick={(e) => {
                                  this.handleEdit(e, record.id);
                                }}
                              >
                                <i className="fa fa-pencil"></i>
                              </button>*/}
                              <button
                                className="btn btn-outline-warning"
                                onClick={(e) => {
                                  this.handleJobListEdit(e, record.id);
                                }}
                              >
                                <i className="fa fa-pencil"></i>
                              </button>
                              <Popconfirm
                                title=" Are you sure?"
                                onConfirm={(e) => {
                                  this.handleJobDelete(e, record.id);
                                }}
                              >
                                <button className="btn btn-outline-danger">
                                  <i className="fa fa-trash"></i>
                                </button>
                              </Popconfirm>
                            </React.Fragment>
                          );
                        },
                      },
                    ]}
                    dataSource={this.state.data}
                    loading={this.state.loading}
                    pagination={this.state.pagination}
                    onChange={this.handleTableChange}
                    scroll={{ x: true }}
                  ></Table>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard_contractor;
