import React, { Component } from "react";
import Datatable from "../Bricks/Datatable";
class WSList extends Component {
  state = {};
  handleEdit = (e, id) => {
    this.props.history.push("/wsedit/" + id);
  };
  handleView = (e, id) => {
    this.props.history.push("/wsview/" + id);
  };
  actions = (id) => {
    return (
      <React.Fragment>
        <button
          className="btn btn-outline-warning"
          onClick={(e) => {
            this.handleEdit(e, id);
          }}
        >
          <i className="fa fa-pencil"></i>
        </button>
      </React.Fragment>
    );
  };
  render() {
    return (
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <Datatable
            blockUI={this.props.blockUI}
            title="Wholesale Distributors"
            url={"ws_listp"}
            onAddClick={this.onAddClick}
            fields_to_show={[
              "Firm Name",
              "Contact Name",
              "street_address",
              "city",
              "state",
              "phone_number",
              "email_address",
              "TOTAL BOMS SENT",
            ]}
            actionobj={this.actions}
          />
        </div>
      </div>
    );
  }
}

export default WSList;
