import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, notification } from "antd";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
const axios = require("axios");
class Register extends Component {
  state = {
    user: {
      first_name: "",
      last_name: "",
      street_address: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
      phone_number: "",
      years_in_business: 0,
      no_of_technicians: 0,
      role: "contractor",
      status: "active",
      email_address: "",
      password: "",
      confirm_password: "",
    },
  };
  handleRegister = (e) => {
    if (this.state.user.password !== this.state.user.confirm_password) {
      notification.error({
        message: "Passwords need to match. Try again please",
        placement: "bottomRight",
      });
      return false;
    }
    this.props.blockUI(true);
    axios
      .post(process.env.REACT_APP_API_URL + "iregister", this.state.user)
      .then((resp) => {
        if (resp.data.status == true) {
          notification.success({
            message: "You have been registered successfully",
            placement: "bottomRight",
          });
          this.props.history.push("/login");
        }
      })
      .catch((resp) => {
        let msg = [];
        if (resp.response) {
          resp = resp.response;
          if (resp.status == 422) {
            for (var i in resp.data) {
              msg.push(resp.data[i]);
            }
          }
        }
        notification.error({
          message: "Your registration attempt failed. Try again please",
          description: msg,
          placement: "bottomRight",
          duration: 60,
        });
      })
      .then((resp) => {
        this.props.blockUI(false);
      });
  };
  render() {
    return (
      <React.Fragment>
        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper d-flex align-items-center auth register-bg-1 theme-one">
              <div className="row w-100">
                <div className="col-md-12 text-center">
                  <img src="./assets/images/auth/logo.png" />
                </div>
                <div className="col-md-10 col-sm-6 mx-auto">
                  <h2 className="text-center mb-4" style={{ color: "white" }}>
                    Register
                  </h2>
                  <div className="auto-form-wrapper">
                    <form
                      action="#"
                      onSubmit={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    >
                      <div className="row">
                        <div className="col-lg-6 col-sm-12">
                          <div className="form-group">
                            <label className="label">Company</label>
                            <input
                              required
                              type="text"
                              className="form-control"
                              value={this.state.user.company}
                              onChange={(e) => {
                                let temp = this.state.user;
                                temp.company = e.target.value;
                                this.setState({ user: temp });
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label className="label">First Name</label>
                            <input
                              required
                              type="text"
                              className="form-control"
                              value={this.state.user.first_name}
                              onChange={(e) => {
                                let temp = this.state.user;
                                temp.first_name = e.target.value;
                                this.setState({ user: temp });
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label className="label">Last Name</label>
                            <input
                              required
                              type="text"
                              className="form-control"
                              value={this.state.user.last_name}
                              onChange={(e) => {
                                let temp = this.state.user;
                                temp.last_name = e.target.value;
                                this.setState({ user: temp });
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label className="label">Address</label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.user.street_address}
                              onChange={(e) => {
                                let temp = this.state.user;
                                temp.street_address = e.target.value;
                                this.setState({ user: temp });
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label className="label">Country</label>
                            <CountryDropdown
                              value={this.state.user.country}
                              onChange={(val) => {
                                let temp = this.state.user;
                                temp.country = val;
                                this.setState({ user: temp });
                              }}
                              priorityOptions={["US"]}
                              classes="form-control"
                            />
                          </div>
                          <div className="form-group">
                            <label className="label">State</label>

                            <RegionDropdown
                              country={this.state.user.country}
                              value={this.state.user.state}
                              onChange={(val) => {
                                let temp = this.state.user;
                                temp.state = val;
                                this.setState({ user: temp });
                              }}
                              classes="form-control"
                            />
                          </div>
                          <div className="form-group">
                            <label className="label">City</label>

                            <input
                              type="text"
                              className="form-control"
                              value={this.state.user.city}
                              onChange={(e) => {
                                let temp = this.state.user;
                                temp.city = e.target.value;
                                this.setState({ user: temp });
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label className="label">Zip Code</label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.user.postal_code}
                              onChange={(e) => {
                                let temp = this.state.user;
                                temp.postal_code = e.target.value;
                                this.setState({ user: temp });
                              }}
                            />
                          </div>

                          <div className="form-group">
                            <label className="label">Phone Number</label>
                            <input
                              required
                              type="text"
                              className="form-control"
                              value={this.state.user.phone_number}
                              onChange={(e) => {
                                let temp = this.state.user;
                                temp.phone_number = e.target.value;
                                this.setState({ user: temp });
                              }}
                            />
                          </div>

                          <div className="form-group">
                            <label className="label">Email Address</label>
                            <input
                              required
                              type="text"
                              className="form-control"
                              value={this.state.user.email_address}
                              onChange={(e) => {
                                let temp = this.state.user;
                                temp.email_address = e.target.value;
                                this.setState({ user: temp });
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label className="label">Years In Business</label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.user.years_in_business}
                              onChange={(e) => {
                                let temp = this.state.user;
                                temp.years_in_business = e.target.value;
                                this.setState({ user: temp });
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label className="label">
                              Number of Technicians
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.user.no_of_technicians}
                              onChange={(e) => {
                                let temp = this.state.user;
                                temp.no_of_technicians = e.target.value;
                                this.setState({ user: temp });
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label className="label">Password</label>

                            <input
                              required
                              type="password"
                              className="form-control"
                              value={this.state.user.password}
                              onChange={(e) => {
                                let temp = this.state.user;
                                temp.password = e.target.value;
                                this.setState({ user: temp });
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label className="label">Confirm Password</label>

                            <input
                              type="password"
                              className="form-control"
                              value={this.state.user.confirm_password}
                              onChange={(e) => {
                                let temp = this.state.user;
                                temp.confirm_password = e.target.value;
                                this.setState({ user: temp });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <p
                            style={{
                              color: "#102EB4",
                              marginBottom: "17px",
                            }}
                          >
                            When your Bill of Materials (BOM) is completed it
                            will be sent to you. You may also elect for it to be
                            sent to a wholesaler(s) of your choice. When you
                            submit a new job, you can elect to have it sent to 0
                            – 3 wholesaler(s) of your choice, at that time.
                          </p>
                          {/******Whole seller 1 */}
                          <div className="form-group">
                            <b className="label">
                              Wholesale Distributor Name # 1
                            </b>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.user.ws1_name}
                              onChange={(e) => {
                                let temp = this.state.user;
                                temp.ws1_name = e.target.value;
                                this.setState({ user: temp });
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label className="label">
                              Wholesale Distributor Contact Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.user.ws1_contact_name}
                              onChange={(e) => {
                                let temp = this.state.user;
                                temp.ws1_contact_name = e.target.value;
                                this.setState({ user: temp });
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label className="label">
                              Wholesale Distributor Contact Email
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.user.ws1_email_address}
                              onChange={(e) => {
                                let temp = this.state.user;
                                temp.ws1_email_address = e.target.value;
                                this.setState({ user: temp });
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label className="label">
                              Wholesale Distributor Contact Phone #
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.user.ws1_phone_number}
                              onChange={(e) => {
                                let temp = this.state.user;
                                temp.ws1_phone_number = e.target.value;
                                this.setState({ user: temp });
                              }}
                            />
                          </div>
                          {/********************** */}
                          {/**********Wholeseller2 */}
                          <div className="form-group">
                            <b className="label">
                              Wholesale Distributor Name # 2
                            </b>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.user.ws2_name}
                              onChange={(e) => {
                                let temp = this.state.user;
                                temp.ws2_name = e.target.value;
                                this.setState({ user: temp });
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label className="label">
                              Wholesale Distributor Contact name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.user.ws2_contact_name}
                              onChange={(e) => {
                                let temp = this.state.user;
                                temp.ws2_contact_name = e.target.value;
                                this.setState({ user: temp });
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label className="label">
                              Wholesale Distributor Contact Email
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.user.ws2_email_address}
                              onChange={(e) => {
                                let temp = this.state.user;
                                temp.ws2_email_address = e.target.value;
                                this.setState({ user: temp });
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label className="label">
                              Wholesale Distributor Contact Phone #
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.user.ws2_phone}
                              onChange={(e) => {
                                let temp = this.state.user;
                                temp.ws2_phone_number = e.target.value;
                                this.setState({ user: temp });
                              }}
                            />
                          </div>
                          {/************************* */}
                          {/**************Wholeseller3 */}
                          <div className="form-group">
                            <b className="label">
                              Wholesale Distributor Name # 3
                            </b>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.user.ws3_name}
                              onChange={(e) => {
                                let temp = this.state.user;
                                temp.ws3_name = e.target.value;
                                this.setState({ user: temp });
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label className="label">
                              Wholesale Distributor Contact Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.user.ws3_contact_name}
                              onChange={(e) => {
                                let temp = this.state.user;
                                temp.ws3_contact_name = e.target.value;
                                this.setState({ user: temp });
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label className="label">
                              Wholesale Distributor Contact Email
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.user.ws3_email_address}
                              onChange={(e) => {
                                let temp = this.state.user;
                                temp.ws3_email_address = e.target.value;
                                this.setState({ user: temp });
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label className="label">
                              Wholesale Distributor Contact Phone #
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.user.ws3_phone_number}
                              onChange={(e) => {
                                let temp = this.state.user;
                                temp.ws3_phone_number = e.target.value;
                                this.setState({ user: temp });
                              }}
                            />
                          </div>
                          {/************************************** */}
                        </div>
                      </div>
                      <div className="col-lg-6 mx-auto">
                        <div className="form-group">
                          <button
                            className="btn btn-primary submit-btn btn-block"
                            onClick={this.handleRegister}
                            style={{ color: "white" }}
                          >
                            Register
                          </button>
                        </div>
                        <div className="text-block text-center my-3">
                          <span className="text-small font-weight-semibold">
                            Already have and account ?
                          </span>
                          <Link to="/login" className="text-black text-small">
                            Login
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- content-wrapper ends -->*/}
          </div>
          {/*<!-- page-body-wrapper ends -->*/}
        </div>
      </React.Fragment>
    );
  }
}

export default Register;
