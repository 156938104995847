import React, { Component } from "react";
import { Collapse } from "antd";
import "antd/dist/antd.css";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";

import { Upload, message, Image, Button, notification } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
const axios = require("axios");
const { Panel } = Collapse;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

class AccountSettings extends Component {
  state = {
    user: {
      first_name: "",
      last_name: "",
      profile_pic: "",
      street_address: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
      phone_number: "",
      years_in_business: "",
      no_of_technicians: "",
      email_address: "",
      password: "",
      role: "contractor",
      status: "",
    },
    ws1: { name: "", contact_name: "", email_address: "", phone_number: "" },
    ws2: { name: "", contact_name: "", email_address: "", phone_number: "" },
    ws3: { name: "", contact_name: "", email_address: "", phone_number: "" },
    profile_pic: "",
    loading: false,
    user_id: "",
  };
  componentDidMount() {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      this.setState(
        { user_id: user.id, profile_pic: user.profile_pic },
        function () {
          this.initCom();
        }
      );
    }
  }
  initCom = () => {
    this.props.blockUI(true);
    axios
      .get(process.env.REACT_APP_API_URL + "users/view/" + this.state.user_id)
      .then((resp) => {
        let rru = resp.data.user;
        let user = {
          first_name: rru.first_name,
          last_name: rru.last_name,
          street_address: rru.street_address,
          city: rru.city,
          state: rru.state,
          company: rru.company,
          postal_code: rru.postal_code,
          country: rru.country,
          phone_number: rru.phone_number,
          years_in_business: rru.years_in_business,
          no_of_technicians: rru.no_of_technicians,
          email_address: rru.email_address,
          password: "",
          role: rru.role,
          status: rru.status,
        };
        if (resp.data.ws1) {
          this.setState({ ws1: resp.data.ws1 });
        }
        if (resp.data.ws2) {
          this.setState({ ws2: resp.data.ws2 });
        }
        if (resp.data.ws3) {
          this.setState({ ws3: resp.data.ws3 });
        }
        this.setState({ user });
      })
      .catch((resp) => {})
      .then((resp) => {
        this.props.blockUI(false);
      });
  };
  handleEditUser = (e) => {
    this.props.blockUI(true);
    axios
      .post(
        process.env.REACT_APP_API_URL + "users/update/" + this.state.user_id,
        {
          user: this.state.user,
          ws1: this.state.ws1,
          ws2: this.state.ws2,
          ws3: this.state.ws3,
        }
      )
      .then((resp) => {
        if (resp.data.status)
          notification.success({
            message: "User Edited.",
            placement: "bottomRight",
          });
        else
          notification.error({
            message: "User Edit Attempt Failed.",
            placement: "bottomRight",
          });
      })
      .catch((resp) => {
        notification.error({
          message: "User Edit Attempt Failed.",
          placement: "bottomRight",
        });
      })
      .then((resp) => {
        let user = JSON.parse(localStorage.getItem("user"));
        user.first_name = this.state.user.first_name;
        user.last_name = this.state.user.last_name;
        localStorage.setItem("user", JSON.stringify(user));
        this.props.blockUI(false);
      });
  };
  beforeUpload = (file) => {
    //console.log(file.originFileObj);
    //return false;
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
    }
    getBase64(file, (imageUrl) => {
      console.log(imageUrl);
      this.setState({ profile_pic: imageUrl }, function () {
        //do the upload
        this.props.blockUI(true);
        axios
          .post(
            process.env.REACT_APP_API_URL +
              "users/update_avatar/" +
              this.state.user_id,
            { avatar: this.state.profile_pic }
          )
          .then((resp) => {
            if (resp.data.status)
              notification.success({
                message: "User Avatar Edited.",
                placement: "bottomRight",
              });
          })
          .catch((resp) => {})
          .then((resp) => {
            let user = JSON.parse(localStorage.getItem("user"));
            user.profile_pic = this.state.profile_pic;
            localStorage.setItem("user", JSON.stringify(user));
            this.props.blockUI(false);
          });
      });
    });
    return false;
  };
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <h4 className="col-md-6">My Account</h4>
                  <div className="col-md-6 text-right">
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      beforeUpload={(file) => this.beforeUpload(file)}
                    >
                      {this.state.profile_pic ? (
                        <img
                          src={this.state.profile_pic}
                          alt="avatar"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        <div>
                          {this.state.loading ? (
                            <LoadingOutlined />
                          ) : (
                            <PlusOutlined />
                          )}
                          <div style={{ marginTop: 8 }}>Upload</div>
                        </div>
                      )}
                    </Upload>
                    <p>Click on Avatar to change</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label>First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.user.first_name}
                        onChange={(e) => {
                          let temp = this.state.user;
                          temp.first_name = e.target.value;
                          this.setState({ user: temp });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label>Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.user.last_name}
                        onChange={(e) => {
                          let temp = this.state.user;
                          temp.last_name = e.target.value;
                          this.setState({ user: temp });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label>Phone Number</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.user.phone_number}
                        onChange={(e) => {
                          let temp = this.state.user;
                          temp.phone_number = e.target.value;
                          this.setState({ user: temp });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label>Address</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.user.street_address}
                        onChange={(e) => {
                          let temp = this.state.user;
                          temp.street_address = e.target.value;
                          this.setState({ user: temp });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label>City</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.user.city}
                        onChange={(e) => {
                          let temp = this.state.user;
                          temp.city = e.target.value;
                          this.setState({ user: temp });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label>State</label>
                      {/*<input
                        type="text"
                        className="form-control"
                        value={this.state.user.state}
                        onChange={(e) => {
                          let temp = this.state.user;
                          temp.state = e.target.value;
                          this.setState({ user: temp });
                        }}
                      />*/}
                      <RegionDropdown
                        countryValueType="short"
                        country="US"
                        value={this.state.user.state}
                        onChange={(val) => {
                          let temp = this.state.user;
                          temp.state = val;
                          this.setState({ user: temp });
                        }}
                        classes="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label>Zip Code</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.user.postal_code}
                        onChange={(e) => {
                          let temp = this.state.user;
                          temp.postal_code = e.target.value;
                          this.setState({ user: temp });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label>Company</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.user.company}
                        onChange={(e) => {
                          let temp = this.state.user;
                          temp.company = e.target.value;
                          this.setState({ user: temp });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label>Email Address</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.user.email_address}
                        onChange={(e) => {
                          let temp = this.state.user;
                          temp.email_address = e.target.value;
                          this.setState({ user: temp });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label>Password</label>
                      <input
                        placeholder="Leave empty if not going to update"
                        type="text"
                        className="form-control"
                        value={this.state.user.password}
                        onChange={(e) => {
                          let temp = this.state.user;
                          temp.password = e.target.value;
                          this.setState({ user: temp });
                        }}
                      />
                    </div>
                  </div>
                  {this.state.user.role == "contractor" ? (
                    <React.Fragment>
                      <div className="col-md-4">
                        <div className="form-group row">
                          <label>Years in Business</label>
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.user.years_in_business}
                            onChange={(e) => {
                              let temp = this.state.user;
                              temp.years_in_business = e.target.value;
                              this.setState({ user: temp });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group row">
                          <label>Number of Technicians</label>
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.user.no_of_technicians}
                            onChange={(e) => {
                              let temp = this.state.user;
                              temp.no_of_technicians = e.target.value;
                              this.setState({ user: temp });
                            }}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                  {this.state.user.role !== "contractor" ? (
                    <React.Fragment>
                      <div className="col-md-4">
                        <div className="form-group row">
                          <label>Role</label>
                          <select
                            value={this.state.user.role}
                            onChange={(e) => {
                              let temp = this.state.user;
                              temp.role = e.target.value;
                              this.setState({ user: temp });
                            }}
                            className="form-control"
                            disabled
                          >
                            <option value="contractor">Contractor</option>
                            <option value="wallace_staff">Staff</option>
                            <option value="superadmin">Super Admin</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group row">
                          <label>Status</label>
                          <select
                            value={this.state.user.status}
                            onChange={(e) => {
                              let temp = this.state.user;
                              temp.status = e.target.value;
                              this.setState({ user: temp });
                            }}
                            className="form-control"
                            disabled
                          >
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </select>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </div>
                {/*this.state.user.role == "contractor" ? (
                  <React.Fragment>
                    
                    <Collapse
                      style={{
                        marginLeft: "-13px",
                        marginTop: "0px",
                        marginRight: "-13px",
                      }}
                    >
                      <Panel header="Wholesaler One">
                        <div className="row">
                          {" "}
                          <div className="col-md-3">
                            <div className="form-group row">
                              <label>Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.ws1.name}
                                onChange={(e) => {
                                  let temp = this.state.ws1;
                                  temp.name = e.target.value;
                                  this.setState({ ws1: temp });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group row">
                              <label>Contact Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.ws1.contact_name}
                                onChange={(e) => {
                                  let temp = this.state.ws1;
                                  temp.contact_name = e.target.value;
                                  this.setState({ ws1: temp });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group row">
                              <label>Contact Email</label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.ws1.email_address}
                                onChange={(e) => {
                                  let temp = this.state.ws1;
                                  temp.email_address = e.target.value;
                                  this.setState({ ws1: temp });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group row">
                              <label>Contact Phone</label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.ws1.phone_number}
                                onChange={(e) => {
                                  let temp = this.state.ws1;
                                  temp.phone_number = e.target.value;
                                  this.setState({ ws1: temp });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Panel>
                    </Collapse>
                    
                    
                    <Collapse
                      style={{
                        marginLeft: "-13px",
                        marginTop: "0px",
                        marginRight: "-13px",
                      }}
                    >
                      <Panel header="Wholesaler Two">
                        <div className="row">
                          {" "}
                          <div className="col-md-3">
                            <div className="form-group row">
                              <label>Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.ws2.name}
                                onChange={(e) => {
                                  let temp = this.state.ws2;
                                  temp.name = e.target.value;
                                  this.setState({ ws2: temp });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group row">
                              <label>Contact Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.ws2.contact_name}
                                onChange={(e) => {
                                  let temp = this.state.ws2;
                                  temp.contact_name = e.target.value;
                                  this.setState({ ws2: temp });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group row">
                              <label>Contact Email</label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.ws2.email_address}
                                onChange={(e) => {
                                  let temp = this.state.ws2;
                                  temp.email_address = e.target.value;
                                  this.setState({ ws2: temp });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group row">
                              <label>Contact Phone</label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.ws2.phone_number}
                                onChange={(e) => {
                                  let temp = this.state.ws2;
                                  temp.phone_number = e.target.value;
                                  this.setState({ ws2: temp });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Panel>
                    </Collapse>
                    
                    <Collapse
                      style={{
                        marginLeft: "-13px",
                        marginTop: "0px",
                        marginRight: "-13px",
                      }}
                    >
                      <Panel header="Wholesaler Three">
                        <div className="row">
                          {" "}
                          <div className="col-md-3">
                            <div className="form-group row">
                              <label>Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.ws3.name}
                                onChange={(e) => {
                                  let temp = this.state.ws3;
                                  temp.name = e.target.value;
                                  this.setState({ ws3: temp });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group row">
                              <label>Contact Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.ws3.contact_name}
                                onChange={(e) => {
                                  let temp = this.state.ws3;
                                  temp.contact_name = e.target.value;
                                  this.setState({ ws3: temp });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group row">
                              <label>Contact Email</label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.ws3.email_address}
                                onChange={(e) => {
                                  let temp = this.state.ws3;
                                  temp.email_address = e.target.value;
                                  this.setState({ ws3: temp });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group row">
                              <label>Contact Phone</label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.ws3.phone_number}
                                onChange={(e) => {
                                  let temp = this.state.ws3;
                                  temp.phone_number = e.target.value;
                                  this.setState({ ws3: temp });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Panel>
                    </Collapse>
                    
                  </React.Fragment>
                ) : (
                  ""
                )*/}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <button
              className="btn btn-lg btn-success"
              type="button"
              onClick={this.handleEditUser}
              style={{ backgroundColor: "#102EB4", border: "0" }}
            >
              Submit
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AccountSettings;
