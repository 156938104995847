import React, { Component } from "react";

import "antd/dist/antd.css";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import { Button, notification } from "antd";

const axios = require("axios");

class UserEdit extends Component {
  state = {
    user: {
      first_name: "",
      last_name: "",
      street_address: "",
      city: "",
      state: "",
      postal_code: "",
      country: "US",
      phone_number: "",
      company: "",
      years_in_business: "",
      no_of_technicians: "",
      email_address: "",
      password: "",
      role: "contractor",
      status: "",
    },
    loggedin_user: null,
  };
  componentDidMount() {
    this.setState({ loggedin_user: this.props.user }, function () {
      this.initCom();
    });
  }
  componentDidUpdate(prevProps) {
    let nowUser = this.props.user;
    let cUser = this.state.loggedin_user;
    let change_exist = false;
    if (cUser == undefined || cUser == null || cUser == {}) {
      if (nowUser !== undefined && nowUser !== null && nowUser !== {})
        this.setState({ loggedin_user: nowUser });
    }
    for (var i in nowUser) {
      if (nowUser[i] !== cUser[i]) {
        change_exist = true;
        cUser[i] = nowUser[i];
      }
    }
    if (change_exist) this.setState({ loggedin_user: cUser });
  }
  initCom = () => {
    this.props.blockUI(true);
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "users/view/" +
          this.props.match.params.id
      )
      .then((resp) => {
        let rru = resp.data.user;
        let user = {
          first_name: rru.first_name,
          last_name: rru.last_name,
          street_address: rru.street_address,
          city: rru.city,
          state: rru.state,
          postal_code: rru.postal_code,
          company: rru.company,
          country: "US",
          phone_number: rru.phone_number,
          years_in_business: rru.years_in_business,
          no_of_technicians: rru.no_of_technicians,
          email_address: rru.email_address,
          password: "",
          role: rru.role,
          status: rru.status,
        };
        this.setState({ user });
      })
      .catch((resp) => {})
      .then((resp) => {
        this.props.blockUI(false);
      });
  };
  handleEditUser = (e) => {
    this.props.blockUI(true);
    axios
      .post(
        process.env.REACT_APP_API_URL +
          "users/update/" +
          this.props.match.params.id,
        { user: this.state.user }
      )
      .then((resp) => {
        if (resp.data.status)
          notification.success({
            message: "User Edited.",
            placement: "bottomRight",
          });
      })
      .catch((resp) => {
        let msg = [];
        if (resp.response) {
          resp = resp.response;
          if (resp.status == 422) {
            for (var i in resp.data) {
              msg.push(resp.data[i]);
            }
          }
        }
        notification.error({
          message: "Failed to Edit User.",
          description: msg.map((m) => <p>{m}</p>),
          placement: "bottomRight",
          duration: 60,
        });
      })
      .then((resp) => {
        this.props.blockUI(false);
      });
  };
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4>
                  Edit{" "}
                  {this.state.loggedin_user !== undefined &&
                  this.state.loggedin_user !== null
                    ? this.state.loggedin_user.role == "superadmin"
                      ? "User"
                      : "Contractor"
                    : ""}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label>First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.user.first_name}
                        onChange={(e) => {
                          let temp = this.state.user;
                          temp.first_name = e.target.value;
                          this.setState({ user: temp });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label>Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.user.last_name}
                        onChange={(e) => {
                          let temp = this.state.user;
                          temp.last_name = e.target.value;
                          this.setState({ user: temp });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label>Phone Number</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.user.phone_number}
                        onChange={(e) => {
                          let temp = this.state.user;
                          temp.phone_number = e.target.value;
                          this.setState({ user: temp });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label>Address</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.user.street_address}
                        onChange={(e) => {
                          let temp = this.state.user;
                          temp.street_address = e.target.value;
                          this.setState({ user: temp });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label>City</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.user.city}
                        onChange={(e) => {
                          let temp = this.state.user;
                          temp.city = e.target.value;
                          this.setState({ user: temp });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label>State</label>
                      {/*<input
                        type="text"
                        className="form-control"
                        value={this.state.user.state}
                        onChange={(e) => {
                          let temp = this.state.user;
                          temp.state = e.target.value;
                          this.setState({ user: temp });
                        }}
                      />*/}
                      <RegionDropdown
                        countryValueType="short"
                        country={"US"}
                        value={this.state.user.state}
                        onChange={(val) => {
                          let temp = this.state.user;
                          temp.state = val;
                          this.setState({ user: temp });
                        }}
                        classes="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label>Zip Code</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.user.postal_code}
                        onChange={(e) => {
                          let temp = this.state.user;
                          temp.postal_code = e.target.value;
                          this.setState({ user: temp });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label>Company</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.user.company}
                        onChange={(e) => {
                          let temp = this.state.user;
                          temp.company = e.target.value;
                          this.setState({ user: temp });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label>Email Address</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.user.email_address}
                        onChange={(e) => {
                          let temp = this.state.user;
                          temp.email_address = e.target.value;
                          this.setState({ user: temp });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label>Password</label>
                      <input
                        placeholder="Leave empty if not going to update"
                        type="text"
                        className="form-control"
                        value={this.state.user.password}
                        onChange={(e) => {
                          let temp = this.state.user;
                          temp.password = e.target.value;
                          this.setState({ user: temp });
                        }}
                      />
                    </div>
                  </div>
                  {this.state.user.role == "contractor" ? (
                    <React.Fragment>
                      <div className="col-md-4">
                        <div className="form-group row">
                          <label>Years in Business</label>
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.user.years_in_business}
                            onChange={(e) => {
                              let temp = this.state.user;
                              temp.years_in_business = e.target.value;
                              this.setState({ user: temp });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group row">
                          <label>Number of Technicians</label>
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.user.no_of_technicians}
                            onChange={(e) => {
                              let temp = this.state.user;
                              temp.no_of_technicians = e.target.value;
                              this.setState({ user: temp });
                            }}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label>Role</label>
                      <select
                        value={this.state.user.role}
                        onChange={(e) => {
                          let temp = this.state.user;
                          temp.role = e.target.value;
                          this.setState({ user: temp });
                        }}
                        className="form-control"
                      >
                        <option value="contractor">Contractor</option>
                        {this.state.loggedin_user !== null &&
                        this.state.loggedin_user !== undefined ? (
                          this.state.loggedin_user.role === "superadmin" ? (
                            <React.Fragment>
                              <option value="wallace_staff">Staff</option>
                              <option value="superadmin">Super Admin</option>
                            </React.Fragment>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label>Status</label>
                      <select
                        value={this.state.user.status}
                        onChange={(e) => {
                          let temp = this.state.user;
                          temp.status = e.target.value;
                          this.setState({ user: temp });
                        }}
                        className="form-control"
                      >
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <button
              className="btn btn-lg btn-success"
              type="button"
              onClick={this.handleEditUser}
            >
              Submit
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UserEdit;
