import React, { Component } from "react";
import MenuLayout from "./MenuLayout";
import TopNavBar from "./TopNavBar";
import Footer from "./Footer";
class MasterLayout extends Component {
  state = {};
  render() {
    return (
      <div className="container-scroller">
        <TopNavBar />
        <div className="container-fluid page-body-wrapper">
          <MenuLayout />
          <div className="main-panel">
            <div className="content-wrapper">{this.props.children}</div>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default MasterLayout;
