import React, { Component } from "react";
import { Table, Empty } from "antd";
const axios = require("axios");
class Dashboard extends Component {
  state = {
    user: null,
    jobs_total: 0,
    jobs_completed: 0,
    jobs_forwarded: 0,
    jobs_pending: 0,
    users_total: 0,
    users_active: 0,
    users_inactive: 0,
    /***** */
    data: [],
    loading: false,
    pagination: {
      total: 0,
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
      defaultPageSize: 10,
      defaultCurrent: 1,
      pageSize: 10,
      current: 1,
      showQuickJumper: true,
      showSizeChanger: true,
    },
    sorting: {},
    global_search: "",
  };
  componentDidMount() {
    if (localStorage.getItem("user") != null)
      this.setState(
        { user: JSON.parse(localStorage.getItem("user")) },
        function () {
          this.fetchStats();
          this.fillInTheTable();
        }
      );
  }

  fetchStats = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "users/stats4staff")
      .then((resp) => {
        this.setState({
          jobs_total: resp.data.total_jobs,
          jobs_completed: resp.data.completed_jobs,
          jobs_forwarded: resp.data.forwarded_jobs,
          jobs_pending: resp.data.pending_jobs,
          users_active: resp.data.active_users,
          users_inactive: resp.data.inactive_users,
          users_total: resp.data.total_users,
        });
      })
      .catch((resp) => {})
      .then((resp) => {});
  };
  //Our method to bring in leads list
  fillInTheTable = (params = {}) => {
    this.setState({ loading: true });
    axios
      .post(process.env.REACT_APP_API_URL + "jobs/listad", {
        ...params,
        global_search: this.state.global_search,
      })
      .then((resp) => {
        console.log(resp);
        let data = resp.data;
        this.setState({
          loading: false,
          data: data.results,
          pagination: {
            ...params.pagination,
            total: data.pagination.total,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            showQuickJumper: true,
            showSizeChanger: true,
          },
          sortField: data.sortField,
          sortOrder: data.sortOrder,
        });
      })
      .catch((resp) => {
        console.log(resp);
      })
      .then((resp) => {
        this.setState({ loading: false });
      });
  };
  searchDatatable = (e) => {
    this.setState(
      {
        global_search: e.target.value,
      },
      function () {
        this.fillInTheTable({
          global_search: this.state.global_search,
        });
      }
    );
  };
  //our method to handle table change
  handleTableChange = (pagination, filters, sorter) => {
    this.setState({ pagination: pagination, sorting: sorter });
    this.fillInTheTable({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
    });
  };
  /*********************************** */
  onAddClick = (e) => {
    this.props.history.push("/jobadd");
  };
  handleEdit = (e, id) => {
    this.props.history.push("/jobedit/" + id);
  };
  renderWS = (text) => {
    try {
      let ws_det = text.split(",");
      return (
        <React.Fragment>
          {ws_det[0] !== "" ? (
            <p>
              <b>Name:&nbsp;</b>
              {ws_det[0]}
            </p>
          ) : (
            ""
          )}
          {ws_det[1] !== "" ? (
            <p>
              <b>Phone:&nbsp;</b>
              {ws_det[1]}
            </p>
          ) : (
            ""
          )}
          {ws_det[2] !== "" ? (
            <p>
              <b>Email:&nbsp;</b>
              {ws_det[2]}
            </p>
          ) : (
            ""
          )}
        </React.Fragment>
      );
    } catch (e) {
      return text;
    }
  };
  render() {
    return (
      <React.Fragment>
        {/*Stat Widgets*/}
        <div className="row">
          <div className="col-md-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    <div className="d-flex">
                      <div className="wrapper">
                        <h3 className="mb-0 font-weight-semibold">
                          {this.state.jobs_total}
                        </h3>
                        <h5 className="mb-0 font-weight-medium text-primary">
                          Total BOMs
                        </h5>
                        <p className="mb-0 text-muted"></p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 mt-md-0 mt-4">
                    <div className="d-flex">
                      <div className="wrapper">
                        <h3 className="mb-0 font-weight-semibold">
                          {this.state.jobs_pending}
                        </h3>
                        <h5 className="mb-0 font-weight-medium text-primary">
                          BOMs Submitted
                        </h5>
                        <p className="mb-0 text-muted"></p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-md-0 mt-4">
                    <div className="d-flex">
                      <div className="wrapper">
                        <h3 className="mb-0 font-weight-semibold">
                          {this.state.jobs_forwarded}
                        </h3>
                        <h5 className="mb-0 font-weight-medium text-primary">
                          BOMs In Review
                        </h5>
                        <p className="mb-0 text-muted"></p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-md-0 mt-4">
                    <div className="d-flex">
                      <div className="wrapper">
                        <h3 className="mb-0 font-weight-semibold">
                          {this.state.jobs_completed}
                        </h3>
                        <h5 className="mb-0 font-weight-medium text-primary">
                          BOMs Complete
                        </h5>
                        <p className="mb-0 text-muted"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/***************** */}
        {/*********Stats 2 widget 
        <div className="row">
          <div className="col-md-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="d-flex">
                      <div className="wrapper">
                        <h3 className="mb-0 font-weight-semibold">
                          {this.state.users_total}
                        </h3>
                        <h5 className="mb-0 font-weight-medium text-primary">
                          Total Contractors
                        </h5>
                        <p className="mb-0 text-muted"></p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-md-0 mt-4">
                    <div className="d-flex">
                      <div className="wrapper">
                        <h3 className="mb-0 font-weight-semibold">
                          {this.state.users_active}
                        </h3>
                        <h5 className="mb-0 font-weight-medium text-primary">
                          Active Contractors
                        </h5>
                        <p className="mb-0 text-muted"></p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-md-0 mt-4">
                    <div className="d-flex">
                      <div className="wrapper">
                        <h3 className="mb-0 font-weight-semibold">
                          {this.state.users_inactive}
                        </h3>
                        <h5 className="mb-0 font-weight-medium text-primary">
                          Inactive Contractors
                        </h5>
                        <p className="mb-0 text-muted"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/***************** */}
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            {this.state.user != null ? (
              <div className="card">
                <div className="card-body" style={{ paddingBottom: "5px" }}>
                  <div className="p-4 border-bottom bg-light">
                    <h4
                      className="card-title"
                      style={{ width: "70%", display: "inline" }}
                    >
                      Jobs | Add a Job &nbsp;
                      <small>
                        <button
                          className="btn btn-outline-success btn-rounded"
                          onClick={this.onAddClick}
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </small>
                    </h4>

                    <p
                      className="card-description"
                      style={{ float: "right", display: "inline" }}
                    >
                      <input
                        onChange={this.searchDatatable}
                        value={this.state.global_search}
                        className="form-control"
                        placeholder="Search Here"
                      />{" "}
                    </p>
                  </div>
                  <Table
                    className="table-striped-rows"
                    size={"small"}
                    tableLayout={"auto"}
                    columns={[
                      {
                        title: "Project Name",
                        dataIndex: "name1",
                        sorter: true,
                      },
                      {
                        title: "Date",
                        dataIndex: "date",
                        sorter: true,
                      },
                      {
                        title: "Contractor Name",
                        dataIndex: "contractor_name",
                        sorter: true,
                      },
                      {
                        title: "Status",
                        dataIndex: "status",
                        sorter: true,
                        render: (text) => {
                          if (text == "pending") return "Submitted";
                          if (text == "forwarded") return "In Review";
                          else if (text == "completed") return "Complete";
                        },
                      },
                      {
                        title: "Wholesaler 1",
                        dataIndex: "wholesaler1",
                        sorter: true,
                        render: (text) => {
                          return this.renderWS(text);
                        },
                      },
                      {
                        title: "Wholesaler 2",
                        dataIndex: "wholesaler2",
                        sorter: true,
                        render: (text) => {
                          return this.renderWS(text);
                        },
                      },
                      {
                        title: "Wholesaler 3",
                        dataIndex: "wholesaler3",
                        sorter: true,
                        render: (text) => {
                          return this.renderWS(text);
                        },
                      },
                      {
                        title: "City",
                        dataIndex: "city",
                        sorter: true,
                      },
                      {
                        title: "State",
                        dataIndex: "state",
                        sorter: true,
                      },

                      {
                        title: "Phone Number",
                        dataIndex: "phone_number",
                        sorter: true,
                      },
                      {
                        title: "Email Address",
                        dataIndex: "email_address",
                        sorter: true,
                      },
                      {
                        title: "Contractor Firm",
                        dataIndex: "contractor_firm",
                        sorter: true,
                      },

                      {
                        title: "Actions",
                        dataIndex: "action",
                        render: (text, record, index) => {
                          return (
                            <React.Fragment>
                              <button
                                className="btn btn-outline-warning"
                                onClick={(e) => {
                                  this.handleEdit(e, record.id);
                                }}
                              >
                                <i className="fa fa-pencil"></i>
                              </button>
                              {/*<button
                                className="btn btn-warning"
                                onClick={(e) => {
                                  
                                }}
                              >
                                <i className="fa fa-edit"></i>
                              </button>*/}
                            </React.Fragment>
                          );
                        },
                      },
                    ]}
                    dataSource={this.state.data}
                    loading={this.state.loading}
                    pagination={this.state.pagination}
                    onChange={this.handleTableChange}
                    scroll={{ x: true }}
                  ></Table>
                </div>
              </div>
            ) : (
              /*<Datatable
                actionobj={this.actions}
                blockUI={this.props.blockUI}
                title="Jobs | Add Job"
                url={"jobs_listnonpending"}
                onAddClick={(e) => {
                  this.props.history.push("/jobadd");
                }}
                fields_to_show={[
                  "Project Name",
                  "Date",
                  "Contractor Firm",
                  "Contractor Name",
                  "status",
                  "Wholesaler 1",
                  "Wholesaler 2",
                  "Wholesaler 3",
                  "Phone Number",
                  "Email Address",
                  "city",
                  "state",
                ]}
                actions={"edit"}
                actionobj={this.actions}
                handleEdit={(e, id) => this.handleEdit(e, id)}
              />*/
              ""
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
