import React, { Component } from "react";
import ReactDOM from "react-dom";

class JobView extends Component {
  state = {};
  render() {
    return <div className="row"></div>;
  }
}

export default JobView;
