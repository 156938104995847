import React, { Component } from "react";
import { Link } from "react-router-dom";
class TopNavBar extends Component {
  state = {};
  render() {
    return (
      <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
          <a className="navbar-brand brand-logo" href="javascript:;">
            <img src="./assets/images/logo.png" alt="logo" />{" "}
          </a>
          <a className="navbar-brand brand-logo-mini" href="javascript:;">
            <img src="./assets/images/logo.png" alt="logo" />{" "}
          </a>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-center">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown d-none d-xl-inline-block user-dropdown">
              <Link to="/logout" className="dropdown-item">
                Sign Out<i className="dropdown-item-icon ti-power-off"></i>
              </Link>
            </li>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            data-toggle="offcanvas"
          >
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
    );
  }
}

export default TopNavBar;
