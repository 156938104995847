import React, { Component } from "react";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import { Table, Popconfirm, notification, Collapse } from "antd";
const axios = require("axios");
const { Panel } = Collapse;
class MyWSList extends Component {
  columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Contact Name",
      dataIndex: "contact_name",
      key: "contact_name",
    },
    {
      title: "Email Address",
      dataIndex: "email_address",
      key: "email_address",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "state",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (text, row, ind) => (
        <React.Fragment>
          <button
            className="btn btn-warning"
            onClick={(e) => this.handleWSEdit(text)}
          >
            <i className="fa fa-pencil"></i>
          </button>
          &nbsp;
          <Popconfirm
            title={"Are you sure?"}
            onConfirm={(e) => {
              this.handleWSDel(text);
            }}
          >
            <button className="btn btn-danger">
              <i className="fa fa-trash"></i>
            </button>
          </Popconfirm>
        </React.Fragment>
      ),
    },
  ];
  state = {
    user: null,
    wslist: [],
    ws_add: {
      first_name: "",
      last_name: "",
      name: "",
      contact_name: "",
      street_address: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
      phone_number: "",
      email_address: "",
      created_by: 0,
    },
  };
  componentDidMount() {
    if (localStorage.getItem("user") != null)
      this.setState(
        { user: JSON.parse(localStorage.getItem("user")) },
        function () {
          this.initComp();
        }
      );
  }
  initComp() {
    this.props.blockUI(true);
    axios
      .get(process.env.REACT_APP_API_URL + "ws/list/" + this.state.user.id)
      .then((resp) => {
        console.log(resp.data);
        this.setState({ wslist: resp.data.items });
      })
      .catch((err) => {})
      .then((resp) => {
        this.props.blockUI(false);
      });
  }
  handleWSDel = (id) => {
    axios
      .get(process.env.REACT_APP_API_URL + "ws/delete/" + id)
      .then((resp) => {
        if (resp.data.status) {
          this.initComp();
          notification.success({
            message: "Wholesaler Removed.",
            placement: "bottomRight",
          });
        } else {
          notification.error({
            message: "Failed to remove Wholesaler.",
            placement: "bottomRight",
          });
        }
      });
  };
  handleWSEdit = (id) => {
    this.props.history.push("/wsedit/" + id);
  };
  handleAddingWS = (e) => {
    this.props.blockUI(true);
    let temp = this.state.ws_add;
    temp.created_by = this.state.user.id;
    axios
      .post(process.env.REACT_APP_API_URL + "ws/add", temp)
      .then((resp) => {
        if (resp.data.status) {
          this.resetWsAdd();
          notification.success({
            message: "Wholesale Distributor Added.",
            description:
              "Wholesale Distributor has been added. It's available for selection in wholesale ditributors dropdown.",
            placement: "bottomRight",
          });
        }
      })
      .catch((resp) => {
        let msg = [];
        if (resp.response) {
          resp = resp.response;
          if (resp.status == 422) {
            for (var i in resp.data) {
              msg.push(resp.data[i]);
            }
          }
        }
        notification.error({
          message: "Failed",
          description: msg,
          placement: "bottomRight",
          duration: 60,
        });
      })
      .then((resp) => {
        this.props.blockUI(false);
        this.initComp();
      });
  };
  resetWsAdd = () => {
    this.setState({
      ws_add: {
        first_name: "",
        last_name: "",
        name: "",
        contact_name: "",
        street_address: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
        phone_number: "",
        email_address: "",
        created_by: 0,
      },
    });
  };
  render() {
    return (
      <React.Fragment>
        {this.state.wslist.length <= 3 ? (
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  {/****Add wholesaler section below */}
                  <Collapse
                    style={{
                      marginLeft: "-13px",
                      marginTop: "0px",
                      marginRight: "-13px",
                    }}
                  >
                    <Panel header="Add New Wholesale Distributor">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Name</label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.ws_add.name}
                              onChange={(e) => {
                                let temp = this.state.ws_add;
                                temp.name = e.target.value;
                                this.setState({ ws_add: temp });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label> Contact Name</label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.ws_add.contact_name}
                              onChange={(e) => {
                                let temp = this.state.ws_add;
                                temp.contact_name = e.target.value;
                                this.setState({ ws_add: temp });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Phone Number</label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.ws_add.phone_number}
                              onChange={(e) => {
                                let temp = this.state.ws_add;
                                temp.phone_number = e.target.value;
                                this.setState({ ws_add: temp });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Email</label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.ws_add.email_address}
                              onChange={(e) => {
                                let temp = this.state.ws_add;
                                temp.email_address = e.target.value;
                                this.setState({ ws_add: temp });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>City</label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.ws_add.city}
                              onChange={(e) => {
                                let temp = this.state.ws_add;
                                temp.city = e.target.value;
                                this.setState({ ws_add: temp });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>State</label>
                            <RegionDropdown
                              countryValueType="short"
                              country={"US"}
                              value={this.state.ws_add.state}
                              onChange={(val) => {
                                let temp = this.state.ws_add;
                                temp.state = val;
                                this.setState({ ws_add: temp });
                              }}
                              classes="form-control"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <button
                            style={{
                              backgroundColor: "#102EB4",
                              color: "white",
                            }}
                            className="btn btn-outline-info pull-right"
                            onClick={this.handleAddingWS}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </Panel>
                  </Collapse>
                  {/****Add wholesaler section above */}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body" style={{ paddingBottom: "5px" }}>
                <div className="p-4 border-bottom bg-light">
                  <h4
                    className="card-title"
                    style={{ width: "70%", display: "inline" }}
                  >
                    My Wholesalers &nbsp;
                  </h4>
                </div>
                <div style={{ width: "100%", overflow: "auto" }}>
                  <Table
                    columns={this.columns}
                    dataSource={this.state.wslist}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MyWSList;
