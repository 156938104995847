import React, { Component } from "react";
import Datatable from "../Bricks/Datatable";

const axios = require("axios");
const fields_to_show = [
  "Name",
  "TOTAL BOMS",
  "BOMS Submitted",
  "BOMS In Review",
  "BOMS Complete",
];
class ProjectsList extends Component {
  state = { header_array: [], data_array: [] };
  componentDidMount() {
    //fetch data from jobslist method
    this.fetchAndMapData();
  }
  fetchAndMapData = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "projects/list")
      .then((resp) => {
        var data_array = [];

        for (var i = 0; i < resp.data.items.length; i++) {
          var row_array = [];
          var hh = [];
          for (var key in resp.data.items[i]) {
            if (fields_to_show.includes(key)) {
              if (i == 0) {
                if (key.length > 0) {
                  key = key[0].toUpperCase() + key.slice(1).replace("_", " ");
                }
                hh.push(key);
              }
              row_array.push(resp.data.items[i][key]);
            }
          }
          if (i == 0) this.setState({ header_array: hh });

          data_array.push(row_array);
          this.setState({ data_array });
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .then((any) => {});
  };
  render() {
    return (
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body" style={{ paddingBottom: "5px" }}>
              <h4
                className="card-title"
                style={{ width: "70%", display: "inline" }}
              >
                Project Type
              </h4>
              <p
                className="card-description"
                style={{ float: "right", display: "inline" }}
              ></p>
              <div style={{ width: "100%", overflow: "auto" }}>
                <table className="table" style={{ marginBottom: "5px" }}>
                  <thead>
                    <tr>
                      {this.state.header_array.map((hh) => (
                        <th>{hh}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data_array.map((row) => (
                      <tr>
                        {row.map((e) => (
                          <td>{e}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectsList;
