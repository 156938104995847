import React, { Component } from "react";
const axios = require("axios");

class Datatable extends Component {
  state = {
    url: "",
    header_array: [],
    data_array: [],
    current_page: 1,
    next_page_url: "",
    prev_page_url: "",
    first_page_url: "",
    search: "",
    order_by: "",
    order_dir: "desc",
    from: 0,
    to: 0,
    total: 0,
    row_of_ids: [],
  };
  componentDidMount() {
    this.setState(
      {
        current_page_url:
          process.env.REACT_APP_API_URL +
          this.props.url.replace(/_/g, "/") +
          "?page=1",
      },
      function () {
        this.props.blockUI(true);
        this.fetchAndMapData();
      }
    );
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps.url !== this.props.url) {
      this.setState(
        {
          current_page_url:
            process.env.REACT_APP_API_URL +
            this.props.url.replace(/_/g, "/") +
            "?page=1",
        },
        function () {
          this.props.blockUI(true);
          this.fetchAndMapData();
        }
      );
    }
  };
  fetchAndMapData = () => {
    axios
      .get(
        this.state.current_page_url +
          "&order_by=" +
          this.state.order_by.toLowerCase().replace(" ", "_") +
          "&order_dir=" +
          this.state.order_dir +
          (this.state.search != "" ? "&search=" + this.state.search : "")
      )
      .then((resp) => {
        var data_array = [];
        this.setState({
          next_page_url: resp.data.items.next_page_url,
          prev_page_url: resp.data.items.prev_page_url,
          first_page_url: resp.data.items.first_page_url,
          to: resp.data.items.to == null ? 0 : resp.data.items.to,
          from: resp.data.items.from == null ? 0 : resp.data.items.from,
          total: resp.data.items.total == null ? 0 : resp.data.items.total,
        });
        var row_of_ids = [];
        for (var i = 0; i < resp.data.items.data.length; i++) {
          var row_array = [];
          var hh = [];
          for (var key in resp.data.items.data[i]) {
            if (this.props.fields_to_show.includes(key)) {
              if (i == 0) {
                if (key.length > 0) {
                  const bkey =
                    key[0].toUpperCase() + key.slice(1).replace("_", " ");
                  hh.push(bkey);
                }
              }
              row_array.push(resp.data.items.data[i][key]);
            }
            if (key == "id") {
              row_of_ids.push(resp.data.items.data[i][key]);
            }
          }
          if (i == 0) this.setState({ header_array: hh });
          data_array.push(row_array);
        }
        this.setState({ data_array, row_of_ids }, function () {
          //console.log(this.state.header_array);
          //console.log(this.state.data_array);
        });
      })
      .catch((err) => {
        console.error(err);
      })
      .then((any) => {
        this.props.blockUI(false);
      });
  };
  gotoNextPage = () => {
    this.setState({ current_page_url: this.state.next_page_url }, function () {
      this.fetchAndMapData();
    });
  };
  gotoPreviousPage = () => {
    this.setState({ current_page_url: this.state.prev_page_url }, function () {
      this.fetchAndMapData();
    });
  };
  searchDatatable = (e) => {
    this.setState(
      { search: e.target.value, current_page_url: this.state.first_page_url },
      function () {
        this.fetchAndMapData();
      }
    );
  };
  render() {
    return (
      <div className="card">
        <div className="card-body" style={{ paddingBottom: "5px" }}>
          <div className="p-4 border-bottom bg-light">
            <h4
              className="card-title"
              style={{ width: "70%", display: "inline" }}
            >
              {this.props.title} &nbsp;
              <small>
                <button
                  className="btn btn-outline-success btn-rounded"
                  onClick={this.props.onAddClick}
                >
                  <i className="fa fa-plus"></i>
                </button>
              </small>
            </h4>

            <p
              className="card-description"
              style={{ float: "right", display: "inline" }}
            >
              <input
                onChange={this.searchDatatable}
                value={this.state.search}
                className="form-control"
                placeholder="Search Here"
              />{" "}
            </p>
          </div>
          <div style={{ width: "100%", overflow: "auto" }}>
            <table className="table" style={{ marginBottom: "5px" }}>
              <thead>
                <tr>
                  {this.state.header_array.length > 0 ? (
                    <React.Fragment>
                      {this.state.header_array.map((hh) => (
                        <th key={hh}>
                          <button
                            className="btn btn-default"
                            onClick={(e) => {
                              let dir = "DESC";
                              if (this.state.order_by == hh) {
                                if (this.state.order_dir == "DESC") {
                                  dir = "ASC";
                                }
                              }
                              this.setState(
                                { order_by: hh, order_dir: dir },
                                function () {
                                  this.fetchAndMapData();
                                }
                              );
                            }}
                          >
                            {hh}&nbsp;
                            {this.state.order_by == hh &&
                            this.state.order_dir == "DESC" ? (
                              <i className="fa fa-arrow-down"></i>
                            ) : (
                              <i className="fa fa-arrow-up"></i>
                            )}
                          </button>
                        </th>
                      ))}
                      <th>Actions</th>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>
              <tbody>
                {this.state.data_array.map((row, ind) => (
                  <tr key={"tr_" + ind}>
                    {row.map((e, i) => (
                      <td key={row.id + "_" + i}>{e}</td>
                    ))}
                    <td>
                      {this.props.actionobj ? (
                        this.props.actionobj(this.state.row_of_ids[ind])
                      ) : (
                        <button
                          type="button"
                          className="btn btn-outline-warning"
                          onClick={(e) =>
                            this.props.handleEdit(e, this.state.row_of_ids[ind])
                          }
                        >
                          <i className="fa fa-pencil"></i>
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <p style={{ float: "left" }}>
            Showing {this.state.from} to {this.state.to} from {this.state.total}
          </p>
          <button
            className="btn btn-outline-info"
            style={{
              float: "right",
              backgroundColor: "#102EB4",
              color: "white",
            }}
            disabled={this.state.next_page_url == "" ? true : false}
            onClick={this.gotoNextPage}
          >
            Next
          </button>
          <button
            className="btn btn-outline-primary"
            style={{
              float: "right",
              marginRight: "2px",
              backgroundColor: "#102EB4",
              color: "white",
            }}
            disabled={this.state.prev_page_url == "" ? true : false}
            onClick={this.gotoPreviousPage}
          >
            Previous
          </button>
        </div>
      </div>
    );
  }
}

export default Datatable;
